<template>
  <div>
    <div class="ser-nav">
      <div class="main-con">
        <a
          v-for="(item, index) in titleData"
          :key="index"
          :class="{ active: item.active }"
          @click="changeTab(index, item.type)"
          >{{ item.name }}<i></i
        ></a>
      </div>
    </div>
    <div id="main">
      <div class="container">
        <div class="box">
          <div class="title">
            <span><i></i>搜索结果</span>
          </div>
          <div class="tab">
            <span
              >共<b>{{ count }}</b> 条结果</span
            >
          </div>
          <!-- //图书 期刊 -->
          <div
            class="main-item clearfix book bookStyle"
            v-if="type === 'book' || type === 'periodical'"
          >
            <a-list
              item-layout="vertical"
              :data-source="newDataList"
              :pagination="{
                current: pagination.onpage,
                pageSize: pagination.pageSize,
                total: pagination.total,
                showSizeChanger: false,
                showLessItems: true,
                showQuickJumper: true,
                onChange: onChange,
                onShowSizeChange: onSizeChange,
              }"
            >
              <a-list-item
                slot="renderItem"
                :key="item.sys_fld_doi"
                slot-scope="item"
              >
                <div class="res-list res-list-1 show">
                  <ul class="book-list clearfix">
                    <li>
                      <div class="pic">
                        <a><img :src="item.sys_fld_coverpath" v-if="type === 'book'" :title="item.name" @click="goRouter('bookDetail', item.sys_fld_doi)"/></a>
                        <a><img :src="item.coverpath" :title="item.name" v-if="type === 'periodical'" @click='goPage(item.visitelink)'/></a>
                      </div>
                      <div class="text">
                        <h4>
                          <a
                            v-if="type === 'book'"
                            v-html="item.name"
                            :title="item.name"
                            @click="goRouter('bookDetail', item.sys_fld_doi)"
                          ></a>
                          <a
                            v-if="type === 'periodical'"
                            v-html="item.name"
                            :title="item.name"
                            @click='goPage(item.visitelink)'
                          ></a>
                        </h4>
                        <p v-if="type === 'book'">{{ item.issuedep }}</p>
                        <p v-if="type === 'periodical'">{{ item.description }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </a-list-item>
            </a-list>
          </div>
          <!-- 音频视频 -->
          <div
            class="main-item clearfix book vodio"
            v-if="type === 'audio' || type === 'radio'"
          >
            <a-list
              item-layout="vertical"
              :data-source="newDataList"
              :pagination="{
                current: pagination.onpage,
                pageSize: pagination.pageSize,
                total: pagination.total,
                showSizeChanger: false,
                showLessItems: true,
                showQuickJumper: true,
                onChange: onChange,
                onShowSizeChange: onSizeChange,
              }"
            >
              <a-list-item
                slot="renderItem"
                :key="item.sys_fld_doi"
                slot-scope="item"
              >
                <div class="res-list res-list-1 show">
                  <ul class="book-list clearfix">
                    <li>
                      <div class="pic">
                        <a v-if="type === 'audio'"><img :src="item.sys_fld_coverpath" :title="item.name" @click="goRouter('AudioDetail', item.sys_fld_doi)"  /></a>
                        <a v-if="type === 'radio'"><img :src="item.sys_fld_coverpath" :title="item.name"  @click="goRouter('videoDetail',item.sys_fld_doi)"/></a>
                        <div class="icon-play">
                          <img
                            src="~@/assets/images/btn-play.png"
                            v-if="type === 'audio'"
                            :title="item.name"
                            @click="goRouter('AudioDetail', item.sys_fld_doi)"
                          />
                          <img
                            src="~@/assets/images/icon-play.png"
                            v-if="type === 'radio'"
                            :title="item.name"
                            @click="goRouter('videoDetail',item.sys_fld_doi)"
                          />
                        </div>
                      </div>
                      <div class="text">
                        <h4>
                          <a v-html="item.name" :title="item.name" @click="goRouter('videoDetail',item.sys_fld_doi)" v-if = "type === 'radio'"></a>
                          <a v-html="item.name" :title="item.name"  v-if = "type === 'audio'" @click="goRouter('AudioDetail', item.sys_fld_doi)"></a>
                        </h4>
                        <p>{{ item.issuedep }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </a-list-item>
            </a-list>
          </div>
          <!-- //活动 -->
          <div class="main-item clearfix" v-if="type === 'activity'">
            <a-list
              item-layout="vertical"
              :data-source="activityList"
              :pagination="{
                current: pagination.onpage,
                pageSize: pagination.pageSize,
                total: pagination.total,
                showSizeChanger: false,
                showLessItems: true,
                showQuickJumper: true,
                onChange: onChange,
                onShowSizeChange: onSizeChange,
              }"
            >
              <a-list-item
                slot="renderItem"
                :key="item.urlid"
                slot-scope="item"
              >
                <div class="activityStyle">
                  <div>
                    <a v-html="item.title" :title="item.title" @click="goRouter('newsDetail',item.urlid,0)"></a> <span>{{ item.pubdate }}</span>
                  </div>
                </div>
              </a-list-item>
            </a-list>
          </div>
          <!-- 资讯 -->
          <div class="main-item clearfix" v-if="type === 'news'">
            <a-list
              item-layout="vertical"
              :data-source="newList"
              :pagination="{
                current: pagination.onpage,
                pageSize: pagination.pageSize,
                total: pagination.total,
                showSizeChanger: false,
                showLessItems: true,
                showQuickJumper: true,
                onChange: onChange,
                onShowSizeChange: onSizeChange,
              }"
              class="news-list"
            >
              <a-list-item
                slot="renderItem"
                :key="item.urlid"
                slot-scope="item"
              >
                <h4>
                  <a v-html="item.title" :title="item.title" @click="goRouter('newsDetail',item.urlid,1)"></a>
                </h4>
                <div class="news clearfix">
                  <a class="news-pic fl">
                    <img :src="item.sys_fld_coverpath" @click="goRouter('newsDetail',item.urlid,1)"/>
                  </a>
                  <div class="news-info fr">
                    <p>
                      {{ item.digest }}
                    </p>
                    <div>
                      <span>{{ item.pubdate }}</span>
                      <a @click="goRouter('newsDetail',item.urlid,1)">查看详情</a>
                    </div>
                  </div>
                </div>
              </a-list-item>
            </a-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/services/search";
import { replacePath, replaceNew,isMobile } from "@/utils/util";
export default {
  name: "search-list",
  components: {},
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      key: this.$route.params.key,
      count: null, //总条数
      newList: [],
      activityList: [],
      type: "book",
      titleData: [
        { name: "图书", active: true, type: "book" },
        { name: "听书", active: false, type: "audio" },
        { name: "视频", active: false, type: "radio" },
        { name: "期刊", active: false, type: "periodical" },
        { name: "活动", active: false, type: "activity" },
        { name: "资讯", active: false, type: "news" },
      ],
      newDataList: [],
      pagination: {
        page: 1,
        pageSize: 15,
        pagecount: 1, //资源总页数
        total: 100, //资源总数
        onpage: 1, //每次翻页后，列表组件回返回当前页面并更新此变量，重新请求
      },
    };
  },
  watch: {
    // $route(to,from) {
    //   console.log(to,from,'999');
    // },
    value: function (val) {
      this.key = val;
      this.pagination.page = 1;
      this.pagination.onpage = 1;
      this.pagination.pageSize = 15;
      if (this.type === "book") {
        this.getBookList();
      } else if (this.type === "audio") {
        this.getAudioList();
      } else if (this.type === "radio") {
        this.getvideoList();
      } else if (this.type === "periodical") {
        this.getjournalList();
      } else if (this.type === "activity") {
        this.getNewsList();
      } else if (this.type === "news") {
        this.getNewsListAboutNews();
      }
    },
  },
  created() {
    this.Mobile()
    //获取搜索图书结果
    this.getBookList();
  },
  mounted() {},
  methods: {
    Mobile(){
      if(isMobile()){
        this.$router.push({ name: 'mobileSearch' });
      }
    },
    //跳转到外部
    goPage(url) {
      window.location.href = url
    },
    //跳转
    goRouter(name, query,type) {
      this.$router.push({
        name: name,
        params: {
          doi: query,
          type:type
        },
      });
    },
    //获取资讯搜索结果
    getNewsListAboutNews() {
      this.activityList = [];
      var obj = {
        page: this.pagination.page,
        size: this.pagination.pageSize,
        query: this.key,
        type: "1",
      };
      api.getNewsList(obj).then((res) => {
        if (res.success) {
          this.newList = res.data.list;
          this.newList.map((item) => {
            item.sys_fld_coverpath = replacePath(item.sys_fld_coverpath);
            item.title = replaceNew(item.title, this.key);
          });
          this.count = res.data.count;
          this.pagination.total = res.data.count;
        }
      });
    },
    //获取活动搜索结果
    getNewsList() {
      this.activityList = [];
      var obj = {
        page: this.pagination.page,
        size: this.pagination.pageSize,
        query: this.key,
        type: "0",
      };
      api.getNewsList(obj).then((res) => {
        if (res.success) {
          this.activityList = res.data.list;
          this.activityList.map((item) => {
            item.title = replaceNew(item.title, this.key);
          });
          this.count = res.data.count;
          this.pagination.total = res.data.count;
        }
      });
    },
    //获取期刊搜索结果
    getjournalList() {
      this.newDataList = [];
      var obj = {
        page: this.pagination.page,
        size: this.pagination.pageSize,
        query: this.key,
      };
      api.getjournalList(obj).then((res) => {
        if (res.success) {
          this.newDataList = res.data.list;
          this.newDataList.map((item) => {
            item.coverpath = replacePath(item.coverpath);
            item.name = replaceNew(item.name, this.key);
          });
          this.count = res.data.count;
          this.pagination.total = res.data.count;
        }
      });
    },
    //获取视频搜索结果
    getvideoList() {
      this.newDataList = [];
      var obj = {
        page: this.pagination.page,
        size: this.pagination.pageSize,
        query: this.key,
      };
      api.getvideoList(obj).then((res) => {
        if (res.success) {
          this.newDataList = res.data.list;
          this.newDataList.map((item) => {
            item.sys_fld_coverpath = replacePath(item.sys_fld_coverpath);
            item.name = replaceNew(item.name, this.key);
          });
          this.count = res.data.count;
          this.pagination.total = res.data.count;
        }
      });
    },
    //获取听书搜索结果
    getAudioList() {
      this.newDataList = [];
      var obj = {
        page: this.pagination.page,
        size: this.pagination.pageSize,
        query: this.key,
      };
      api.getAudioList(obj).then((res) => {
        if (res.success) {
          this.newDataList = res.data.list;
          this.newDataList.map((item) => {
            item.sys_fld_coverpath = replacePath(item.sys_fld_coverpath);
            item.name = replaceNew(item.name, this.key);
          });
          this.count = res.data.count;
          this.pagination.total = res.data.count;
        }
      });
    },
    //获取搜索图书结果
    getBookList() {
      this.newDataList = [];
      var obj = {
        page: this.pagination.page,
        size: this.pagination.pageSize,
        query: this.key,
      };
      api.getBookList(obj).then((res) => {
        if (res.success) {
          this.newDataList = res.data.list;
          this.newDataList.map((item) => {
            item.sys_fld_coverpath = replacePath(item.sys_fld_coverpath);
            item.name = replaceNew(item.name, this.key);
          });
          this.count = res.data.count;
          this.pagination.total = res.data.count;
        }
      });
    },
    //切换选项
    changeTab(num, type) {
      this.titleData.map((item, index) => {
        item.active = false;
        if (num === index) {
          item.active = true;
          this.type = item.type;
        }
      });
      this.count = 0;
      this.pagination.page = 1;
      this.pagination.onpage = 1;
      this.pagination.pageSize = 15;
      if (type === "book") {
        this.getBookList();
      } else if (type === "audio") {
        this.pagination.pageSize = 12;
        this.getAudioList();
      } else if (type === "radio") {
        this.pagination.pageSize = 12;
        this.getvideoList();
      } else if (type === "periodical") {
        this.getjournalList();
      } else if (type === "activity") {
        this.getNewsList();
      } else if (type === "news") {
        this.getNewsListAboutNews();
      }
    },
    onSizeChange(current, size) {
      this.pagination.page = 1;
      this.pagination.onpage = 1;
      this.pagination.pageSize = size;
      //this.listrequest(1)
    },
    onChange(pageNumber, pageSize) {
      // 翻页时触发,分页组件绑定页数需求number类型
      this.pagination.onpage = pageNumber;
      this.pagination.page = pageNumber;
      this.pagination.pageSize = pageSize;
      if (this.type === "book") {
        this.getBookList();
      } else if (this.type === "audio") {
        this.getAudioList();
      } else if (this.type === "radio") {
        this.getvideoList();
      } else if (this.type === "periodical") {
        this.getjournalList();
      } else if (this.type === "activity") {
        this.getNewsList();
      } else if (this.type === "news") {
        this.getNewsListAboutNews();
      }
      //   this.initPicList();
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/css/home.less";
</style>
<style lang="less" scoped>
img {
  cursor: pointer;
}
.ser-nav {
  width: 100%;
  height: 56px;
  line-height: 56px;
  margin-top: -57px;
  background: #fff;
  text-align: center;
  a {
    font-size: 20px;
    padding: 0 25px;
    margin: 0 30px;
    position: relative;
    i {
      position: absolute;
      bottom: 0px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 8px solid #d4000e;
      display: none;
    }
  }
  a.active {
    color: #d4000e;
    i {
      display: block;
    }
  }
}
#main {
  margin-top: 30px;
  .main-item {
    padding-top: 0;
  }
  .book {
    .ant-list-item {
      float: left;
      width: 226px;
    }
  }
  .bookStyle {
    .ant-list-item {
      height: 358px;
    }
  }
  .vodio {
    .ant-list-item {
      float: left;
      width: 282px;
    }
  }
  .activityStyle {
    div {
      font-size: 16px;
      padding: 5px 0;
      a {
        width: 80%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 16px;
        vertical-align: text-top;
      }
      a:hover {
        color: #d4000e;
      }
      span {
        float: right;
        color: #6c6c6c;
        font-size: 14px;
        padding: 4px 0;
      }
    }
  }

  .tab {
    padding: 0 20px;
    height: 48px;
    background: #eaeaea;
    border-top: 4px solid #d4000e;
    margin-bottom: 24px;
    span {
      float: right;
      font-size: 14px;
      line-height: 28px;
      margin: 10px 0;
      b {
        color: #d4000e;
      }
    }
  }
  .book-list {
    li {
      float: left;
      width: 190px;
      border: 1px solid #e9e9e9;
      margin: 0 18px 24px;
      .pic {
        position: relative;
        text-align: center;
        padding: 24px 0px;
        // margin: 0 6px;
        border-bottom: 1px solid #e9e9e9;
        img {
          width: 120px;
          height: 164px;
          margin: 0 auto;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
        }
        .icon-play {
          width: 70px;
          height: 70px;
          position: absolute;
          left: 57px;
          top: 71px;
          img {
            width: 70px;
            height: 70px;
          }
        }
      }
      .text {
        // padding: 0 8px;
        text-align: center;
        p {
          width: 100%;
          height: 23px;
          font-size: 14px;
          color: #8d8d8d;
          line-height: 32px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          vertical-align: middle;
        }
        h4 {
          font-size: 12px;
          font-weight: normal;
          a {
            width: 100%;
            height: 46px;
            font-size: 16px;
            padding: 0 20px;
            line-height: 46px;
            border-bottom: 1px solid #e9e9e9;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: middle;
          }
          a:hover {
            color: #d4000e;
          }
        }
      }
    }
  }
  .vodio {
    .book-list {
      li {
        width: 256px;
        .pic {
          padding: 0;
          margin: 0;
          .icon-play {
            left: 50%;
            margin-left: -35px;
            top: 37px;
          }
          a {
            width: 100%;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.book >>> .ant-list-items {
  overflow: hidden;
}
.book >>> .ant-list-vertical .ant-list-item {
  border-bottom: none;
}
.container >>> .ant-list-pagination {
  text-align: center;
}
.container >>> .ant-list-pagination li {
  padding: 0 !important;
  /* border: 1px solid #d9d9d9; */
}
.news-pic img {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div>
    <div id="main">
      <div class="container">
        <div class="main-item clearfix">
          <div class="left-nav fl">
            <div class="user-info">
              <span><img src="~@/assets/images/user-photo.png" alt="" /></span>
              <p>{{ user }}</p>
            </div>
            <ul>
              <li
                v-for="(item, index) in MenuList"
                :class="item.type"
                :key="index"
              >
                <a @click="MenuClick(item)" :class="item.class"
                  ><i></i>{{ item.name }}</a
                >
              </li>
            </ul>
          </div>
          <div class="right-con fr">
            <div class="box">
              <div class="title">
                <span
                  ><i></i>
                  {{
                    selectedMenutype == "collect"
                      ? "我的收藏"
                      : selectedMenutype == "footprint"
                      ? "我的足迹"
                      : "修改密码"
                  }}
                </span>
              </div>
              <div class="tab" v-show="showbody">
                <a
                  href="javascript:;"
                  v-for="(item, index) in TypeList"
                  :class="item.class"
                  :key="index"
                  @click="TypeClick(item)"
                  >{{ item.name }}</a
                >
                <span
                  >共<b>{{ pagination.total }}</b
                  >个{{
                    selectedtype == "book"
                      ? "图书"
                      : selectedtype == "audio"
                      ? "听书"
                      : "视频"
                  }}</span
                >
              </div>
              <!-- <div class="res-list res-list-1 show">
						<ul class="book-list clearfix">
							<li class="active">
								<div class="pic">
									<a href="#"><img src="~@/assets/images/show-img-5.jpg" alt=""/></a>
									<span></span>
								</div>
								<div class="text">
									<h4><a href="#">国学学刊</a></h4>
									<p>吉林出版集团</p>
								</div>	
							</li>
							<li>
								<div class="pic">
									<a href="#"><img src="~@/assets/images/show-img-6.jpg" alt=""/></a>
									<span></span>
								</div>
								<div class="text">
									<h4><a href="#">国学学刊</a></h4>
									<p>吉林出版集团</p>
								</div>	
							</li>
							<li>
								<div class="pic">
									<a href="#"><img src="~@/assets/images/show-img-4.jpg" alt=""/></a>
									<span></span>
								</div>
								<div class="text">
									<h4><a href="#">国学学刊</a></h4>
									<p>吉林出版集团</p>
								</div>	
							</li>
							<li>
								<div class="pic">
									<a href="#"><img src="~@/assets/images/show-img-2.jpg" alt=""/></a>
									<span></span>
								</div>
								<div class="text">
									<h4><a href="#">国学学刊</a></h4>
									<p>吉林出版集团</p>
								</div>	
							</li>
							<li>
								<div class="pic">
									<a href="#"><img src="~@/assets/images/show-img-5.jpg" alt=""/></a>
									<span></span>
								</div>
								<div class="text">
									<h4><a href="#">国学学刊</a></h4>
									<p>吉林出版集团</p>
								</div>	
							</li>
							<li>
								<div class="pic">
									<a href="#"><img src="~@/assets/images/show-img-6.jpg" alt=""/></a>
									<span></span>
								</div>
								<div class="text">
									<h4><a href="#">国学学刊</a></h4>
									<p>吉林出版集团</p>
								</div>	
							</li>
							<li>
								<div class="pic">
									<a href="#"><img src="~@/assets/images/show-img-4.jpg" alt=""/></a>
									<span></span>
								</div>
								<div class="text">
									<h4><a href="#">国学学刊</a></h4>
									<p>吉林出版集团</p>
								</div>	
							</li>
							<li>
								<div class="pic">
									<a href="#"><img src="~@/assets/images/show-img-2.jpg" alt=""/></a>
									<span></span>
								</div>
								<div class="text">
									<h4><a href="#">国学学刊</a></h4>
									<p>吉林出版集团</p>
								</div>	
							</li>
						</ul>
					</div> -->
              <div class="res-list res-list-1" v-show="showbody">
                <a-list
                  :grid="{ gutter: 30, column: 3 }"
                  item-layout="vertical"
                  :data-source="dataResource"
                  :pagination="{
                    current: pagination.onpage,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    showSizeChanger: false,
                    showLessItems: true,
                    showQuickJumper: true,
                    onChange: onChange,
                    onShowSizeChange: onSizeChange,
                  }"
                  class="book-list clearfix"
                >
                  <a-list-item
                    slot="renderItem"
                    :key="item.doi"
                    slot-scope="item"
                    v-if="selectedtype === 'book'"
                    class="book"
                  >
                    <div class="pic" :title="item.name">
                      <a @click="goDetail('bookDetail', item.doi)"
                        ><img :src="item.img" alt=""
                      /></a>
                      <span :title="'删除'" @click="deleteMessage(item, 2)">></span>
                    </div>
                    <div class="text">
                      <h4 :title="item.name">
                        <a @click="goDetail('bookDetail', item.doi)">{{
                          item.name
                        }}</a>
                      </h4>
                      <p>{{ item.issuedep }}</p>
                    </div>
                  </a-list-item>
                  <a-list-item
                    slot="renderItem"
                    :key="item.doi"
                    slot-scope="item"
                    v-else-if="
                      selectedtype === 'audio' || selectedtype === 'video'
                    "
                    class="video"
                  >
                    <div class="pic" :title="item.name">
                      <a
                        @click="
                          goDetail(
                            selectedtype === 'video'
                              ? 'videoDetail'
                              : 'AudioDetail',
                            item.doi
                          )
                        "
                      >
                        <img :src="item.img" alt="" />
                        <div class="icon-play">
                          <img
                            src="~@/assets/images/btn-play.png"
                            v-if="selectedtype === 'audio'"
                            @click="goDetail('AudioDetail', item.doi)"
                          />
                          <img
                            src="~@/assets/images/icon-play.png"
                            v-if="selectedtype === 'video'"
                            @click="
                              goDetail(
                                selectedtype === 'video' ? 'videoDetail' : '',
                                item.doi
                              )
                            "
                          />
                        </div>
                      </a>
                      <span :title="'删除'" @click="deleteMessage(item, 1)"></span>
                    </div>
                    <div class="text">
                      <h4 :title="item.name">
                        <a
                          @click="
                            goDetail(
                              selectedtype === 'video'

                                ? 'videoDetail'
                                : 'AudioDetail',
                              item.doi
                            )
                          "
                          >{{ item.name }}</a
                        >
                      </h4>
                      <p>{{ item.issuedep }}</p>
                    </div>
                  </a-list-item>
                </a-list>
              </div>
              <template v-if="!showbody">
                <update-pass-word />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Api from "@/services/userCenter.js";
import { replacePath ,isMobile} from "@/utils/util";
import UpdatePassWord from "@/components/common/UpdatePassWord.vue";
import { removeAuthorization } from "@/utils/request";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "UserCenter",
  components: { UpdatePassWord },
  data() {
    return {
      currentPage: ["UserCenter"],
      pagination: {
        page: 1,
        pageSize: 8,
        pagecount: 1, //资源总页数
        total: 0, //资源总数
        onpage: 1, //每次翻页后，列表组件回返回当前页面并更新此变量，重新请求
        keyword: "",
        classid: "",
        type: "p",
      },
      showbody: true,
      selectedtype: "book", //我的收藏 资源类型
      selectedMenutype: "collect",
      dataResource: [],
      logResource: [],
      TypeList: [
        {
          name: "图书",
          type: "book",
          class: "active",
        },
        {
          name: "听书",
          type: "audio",
          class: "",
        },
        {
          name: "视频",
          type: "video",
          class: "",
        },
      ],
      MenuList: [
        {
          name: "我的收藏",
          type: "collect",
          class: "active",
        },
        {
          name: "我的足迹",
          type: "footprint",
          class: "",
        },
        {
          name: "修改密码",
          type: "password",
          class: "",
        },
        {
          name: "退出登录",
          type: "exit",
          class: "",
        },
      ],
    };
  },
  watch: {},
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    // this.initTheme("0");
    this.Mobile()
    this.initResources("1");
  },
  methods: {
     Mobile(){
      if(isMobile()){
        this.$router.push({ name: 'mobileUserCenter' });
      }
    },
    ...mapMutations("account", ["removeUser", "removeRoles","removeExpireAt"]),
    logout() {
      console.log("out");
      this.removeUser();
      this.removeRoles();
      this.removeExpireAt();
      removeAuthorization();
      this.$router.push({ name: "Login" });
    },
    goDetail(name, doi) {
      this.$router.push({ name: name, params: { doi: doi } });
    },
    TypeClick(item) {
      this.TypeList.forEach((element) => {
        element.class = "";
      });
      item.class = "active";
      this.selectedtype = item.type;
      this.pagination.page = 1;
      this.pagination.pageSize = item.type == "book" ? 8 : 6;
      this.pagination.pagecount = 0;
      this.pagination.total = 0;
      this.pagination.onpage = 1;
      this.initResources(
        item.type === "book" ? "1" : item.type === "audio" ? "6" : "5"
      );
    },
    MenuClick(item) {
      this.TypeList.forEach((element) => {
        if (element.type == "book") {
          element.class = "active";
        } else {
          element.class = "";
        }
      });
      this.MenuList.forEach((element) => {
        element.class = "";
      });
      item.class = "active";
      this.selectedMenutype = item.type;
      this.pagination.page = 1;
      this.pagination.pageSize = 8;
      this.pagination.pagecount = 0;
      this.pagination.total = 0;
      this.pagination.onpage = 1;
      if (item.type == "exit") {
        this.logout();
      } else if (item.type === "password") {
        this.showbody = false;
      } else {
        this.showbody = true;
        this.selectedtype = "book";
        this.initResources("1");
      }
    },
    initResources(type) {
      //collect 收藏 //footprint 足迹
      if (this.selectedMenutype === "collect") {
        let obj = {
          page: this.pagination.page,
          size: this.pagination.pageSize,
          name: this.user,
          restype: type,
        };
        Api.getFavorList(obj).then((res) => {
          if (res.success) {
            this.dataResource = res.data.list;
            this.dataResource.map(function (data) {
              data.img = replacePath(data.img);
            });
            this.pagination.total = res.data.count;
          } else {
            this.dataResource = [];
          }
        });
      } else if (this.selectedMenutype === "footprint") {
        // getLogList
        let obj = {
          page: this.pagination.page,
          size: this.pagination.pageSize,
          name: this.user,
          restype: type,
        };
        Api.getLogList(obj).then((res) => {
          if (res.success) {
            this.dataResource = res.data.list;
            this.dataResource.map(function (data) {
              data.img = replacePath(data.img);
            });
            this.pagination.total = res.data.count;
          } else {
            this.dataResource = [];
          }
        });
      }
    },
    onSizeChange(current, size) {
      this.pagination.page = 1;
      this.pagination.onpage = 1;
      this.pagination.pageSize = size;
      //this.listrequest(1)
    },
    onChange(pageNumber, pageSize) {
      // 翻页时触发,分页组件绑定页数需求number类型
      this.pagination.onpage = pageNumber;
      this.pagination.page = pageNumber;
      this.pagination.pageSize = pageSize;
      this.initResources(
        this.selectedtype === "book"
          ? "1"
          : this.selectedtype === "audio"
          ? "6"
          : "5"
      );
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/css/user-center.less";
</style>
<style scoped>
.right-con {
  min-height: 641px;
}
.tab {
  text-align: left;
}
.book-list >>> .ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.ant-col-8 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.text p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
@media screen and (max-width: 1380px) {
  #main {
    min-height: 510px;
  }
}
</style>
<style lang = "less" scoped>
.pic {
  position: relative;
  .icon-play {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -35px;
    margin-top: -35px;
    img {
      width: 70px !important;
      height: 70px !important;
    }
  }
}
.text {
  h4 {
    height: 43px;
  }
  p {
    height: 32px;
  }
}
</style>

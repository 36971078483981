<template>
	<div>
		<header class="header">
			<div class="newcontainer">
				<div class="row">
					<div class="col-xs-12 header-title">
						<h4>收藏</h4>
						<a
							@click="goToView('/mobileHome/mobileUserCenter')"
							class="back"
						><i></i></a>
					</div>
				</div>
			</div>
			<div>
				<!--tab导航-->
				<ul
					id="myTabs"
					class="nav nav-tabs"
					role="tablist"
				>
					<li
						v-for="(item, index) in TypeList"
						:class="item.class"
						:key="index"
						@click="TypeClick(item)"
					>
						<a>{{ item.name }}</a>
					</li>
				</ul>
			</div>
		</header>
		<div id="main">
			<div class="content-wrap">
				<div style="background-color: #fff">
					<div
						id="myTabContent"
						class="tab-content"
					>
						<div
							role="tabpanel"
							class="tab-pane fade active in"
							id="recs"
							aria-labelledby="recs-tab"
						>
							<!--图书-->
							<div
								class="con-item book"
								v-if="type === 'book'"
							>
								<div class="newscontainer">
									<van-list
										v-model="vanListLoading"
										:finished="finished"
										:finished-text="finishedText"
										@load="onLoad('book')"
									>
										<li
											v-for="item in list"
											:key="item.doi"
										>
											<div class="row">
												<div class="col-xs-4">
													<a @click="goRouter('MobileBookDetail', item.doi)"><img
															:src="item.imgurl"
															alt=""
														/></a>
												</div>
												<div class="col-xs-8">
													<div class="keys-text">
														<div class="txt">
															<h4
																:title="item.title"
																v-html="item.name"
																@click="goRouter('MobileBookDetail', item.doi)"
															></h4>
															<p class="brief">
																{{ item.digest }}
															</p>
														</div>
														<p class="author">
															<span>{{ item.author }}</span>著
														</p>
													</div>
												</div>
											</div>
										</li>
									</van-list>
								</div>
							</div>
							<!--听书-->
							<div
								class="con-item audio"
								v-if="type === 'audio'"
							>
								<div class="newscontainer">
									<van-list
										v-model="vanListLoading"
										:finished="finished"
										:finished-text="finishedText"
										@load="onLoad('audio')"
									>
										<li
											v-for="item in list"
											:key="item.doi"
										>
											<div class="col-xs-4">
												<div
													class="pic"
													@click="goRouter('MobileAudioDetail', item.doi)"
												>
													<img
														:src="item.imgurl"
														alt=""
													/><span></span>
												</div>
												<div class="keys-text">
													<h4
														:title="item.title"
														v-html="item.name"
														@click="goRouter('MobileAudioDetail', item.doi)"
													></h4>
												</div>
											</div>
										</li>
									</van-list>
								</div>
							</div>
							<!--视频-->
							<div
								class="con-item video"
								v-if="type === 'video'"
							>
								<div class="newscontainer">
									<van-list
										v-model="vanListLoading"
										:finished="finished"
										:finished-text="finishedText"
										@load="onLoad('video')"
									>
										<li
											v-for="item in list"
											:key="item.doi"
										>
											<div class="row">
												<div class="col-xs-12">
													<div
														class="pic"
														@click="goRouter('MobileVideoDetail', item.doi)"
													>
														<img
															:src="item.imgurl"
															alt=""
														/>
														<span></span>
													</div>
													<h5>
														<a
															:title="item.title"
															v-html="item.name"
															@click="goRouter('MobileVideoDetail', item.doi)"
														></a>
													</h5>
													<p>{{ item.author }}</p>
												</div>
											</div>
										</li>
									</van-list>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as Api from "@/services/userCenter.js"
import { replacePath, isMobile } from "@/utils/util"
import { mapGetters, mapMutations } from "vuex"
import { removeAuthorization } from "@/utils/request"
export default {
	name: "MyCollect",
	data () {
		return {
			TypeList: [
				{
					name: "图书",
					type: "book",
					class: "active",
				},
				{
					name: "听书",
					type: "audio",
					class: "",
				},
				{
					name: "视频",
					type: "video",
					class: "",
				},
			],
			type: "book",
			list: [],
			vanListLoading: false, // 加载状态
			finished: false, // 是否加载结束
			finishedText: "已加载全部", // 加载完成后的提示文案
			pages: 0, // 页数
			pnums: 10, // 条数
			total: 0,
			pagecount: 1, //总页数
		}
	},
	watch: {},
	computed: {
		...mapGetters("account", ["user"]),
	},
	created () {
		this.Mobile()
	},
	methods: {
		...mapMutations("account", ["removeUser", "removeRoles", "removeExpireAt"]),
		Mobile () {
			if (!isMobile()) {
				this.$router.push({ name: "userCenter" })
			}
		},
		goToView (path) {
			this.$router.push({ path: path })
		},
		goRouter (name, query) {
			this.$router.push({
				name: name,
				params: {
					doi: query,
				},
			})
		},
		TypeClick (item) {
			this.TypeList.forEach((element) => {
				element.class = ""
			})
			item.class = "active"
			this.type = item.type
			this.list = []
			this.vanListLoading = false
			this.finished = false
			this.total = 0
			this.pages = 0
			this.pnums = 10
			this.pagecount = 1
			this.onLoad(this.type)
		},
		onLoad (type) {
			if (
				localStorage.getItem("expireAt") &&
				new Date().getTime() >=
				new Date(localStorage.getItem("expireAt")).getTime()
			) {
				this.removeUser()
				this.removeRoles()
				this.removeExpireAt()
				removeAuthorization()
				this.$router.push({
					name: "mobileLogin",
				})
				return
			}
			this.pages += 1
			let params = {
				page: this.pages,
				size: this.pnums,
				name: this.user,
				restype: type === "book" ? "1" : type === "audio" ? "6" : "5",
			}
			if (this.pages > this.pagecount) {
				this.finished = true
			} else {
				Api.getFavorList(params).then((res) => {
					if (res.success) {
						this.vanListLoading = false
						const {
							data: { list, pageCount, count },
						} = res
						this.pagecount = pageCount
						this.total = count
						let concatlist = list.map((e) => {
							return {
								name: e.name,
								title: e.name,
								author: e.author,
								digest: e.digest,
								imgurl: replacePath(e.img, "0", "1"),
								issuedep: e.issuedep,
								doi: e.doi,
							}
						})
						this.list = this.list.concat(concatlist)
						if (this.pages >= pageCount) {
							this.finished = true
						}
					} else {
						this.list = []
						this.vanListLoading = false
						this.finished = true
						this.total = 0
						this.pages = 0
						this.pnums = 10
					}
				})
			}
		},
	},
};
</script>
<style lang="less" scoped>
.header {
	top: 0;
	width: 100%;
	padding: 12px 0 2px 0;
	position: fixed;
	background-color: #f0f2f5;
	z-index: 1;
	.newcontainer {
		width: 100%;
		padding-right: 0.3rem;
		padding-left: 0.3rem;
		margin-right: auto;
		margin-left: auto;
		.row {
			margin-right: -0.3rem;
			margin-left: -0.3rem;
			.header-title {
				position: relative;
				h4 {
					text-align: center;
					font-size: 0.28rem;
					color: #232323;
					font-weight: 600;
					padding: 0 1rem;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.back {
					position: absolute;
					left: 0.3rem;
					top: 0rem;
					font-size: 0.28rem;
					color: #fff;
					i {
						width: 0.2rem;
						height: 0.4rem;
						margin-top: -0.04rem;
						margin-right: 0.08rem;
						font-size: 0.28rem;
						color: #232323;
						background: url(~@/assets/images/icon-back.png) no-repeat;
						background-size: 100% auto;
						vertical-align: middle;
					}
				}
			}
			.col-xs-12 {
				width: 100%;
			}
		}
	}
	.nav {
		background: #fff;
		padding: 12px 0;
		height:  0.84rem;
		// border-bottom: 1px solid #e9e9e9;
	}
	.nav-tabs > li {
		float: left;
		width: 33%;
		text-align: center;
		// margin-top: 0.1rem;
		line-height: initial;
		a {
			display: inline-block;
			font-size: 0.28rem;
			color: #666666;
			width: 70%;
			// padding: 5px 0;
			// border-bottom: 2px solid;
			border-color: transparent;
		}
	}
	.nav-tabs > li.active > a,
	.nav-tabs > li.active > a:focus,
	.nav-tabs > li.active > a:hover {
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 2px solid #d4000e;
		color: #1d1d1d;
		font-weight: bold;
	}
}
#main {
	margin-top: 1.7rem;
	.content-wrap {
		padding-bottom: 1.2rem;
		background: #fff;

		#myTabContent {
			width: 100%;
			.con-item {
				// margin-top: 0.1rem;
				// border-top: 2px solid #e9e9e9;
				background: #fff;
				// ul {
				li {
					padding: 0.4rem 0;
					border-bottom: 2px solid #e9e9e9;
				}
			}
			.book {
				.newscontainer {
					padding-right: 0.3rem;
					padding-left: 0.3rem;
					margin-right: auto;
					margin-left: auto;
					.col-xs-8 {
						display: -webkit-box;
						padding-right: 0.25rem;
						//   padding-left: 0.25rem;
						width: 65%;
						.keys-text {
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-box-flex: 1;
							-webkit-box-pack: justify;
							padding-bottom: 0.1rem;
							.txt {
								h4 {
									width: 100%;
									font-size: 0.28rem;
									line-height: 0.48rem;
									font-weight: bold;
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									 -webkit-line-clamp: 2;
									-webkit-box-orient: vertical;
									margin-bottom: 0;
								}
								.brief {
									margin-top: 0.1rem;
									font-size: 0.24rem;
									color: #787878;
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 2;
									-webkit-box-orient: vertical;
									margin-bottom: 0;
									text-indent: 2em;
								}
							}
							.author {
								color: #333333;
								margin-top: 0.2rem;
								margin-bottom: 0;
								span {
									font-size: 0.24rem;
									margin-right: 0.2rem;
								}
							}
							p {
								margin-bottom: 0;
							}
						}
					}
					.row {
						display: -webkit-box;
						margin-right: -0.3rem;
						margin-left: -0.3rem;
					}
					.col-xs-4 {
						padding-top: 0.1rem;
						padding-right: 0.3rem;
						padding-left: 0.3rem;
						width: 2.5rem;
						img {
							background-size: 100% auto;
							box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
							width: 1.9rem;
							height: 2.696rem;
						}
					}
				}
			}

			.audio {
				.newscontainer {
					padding-right: 0.3rem;
					padding-left: 0.3rem;
					margin-right: auto;
					margin-left: auto;
					.van-list {
						li:first-child {
							border-top: none;
						}
						li {
							width: 45%;
							display: -webkit-inline-box;
							margin-left: 0.15rem;

							padding-bottom: 0;
						}
						li:nth-of-type(odd) {
							margin-right: 0.16rem;
						}
					}

					.col-xs-4 {
						padding-right: 0px;
						//   padding-left: 0.3rem;
						width: 100%;
						border-left: 2px solid #e9e9e9;
						border-right: 2px solid #e9e9e9;
						border-top: 2px solid #e9e9e9;
						box-shadow: 0 3px 5px rgba(0, 0, 0, 0.175);
						.pic {
							position: relative;
							img {
								max-width: 100%;
								width: 100%;
								height: 1.9rem;
								min-height: 1.4rem;
							}
							span {
								position: absolute;
								left: 50%;
								top: 50%;
								-webkit-transform: translate(-50%, -50%);
								-moz-transform: translate(-50%, -50%);
								transform: translate(-50%, -50%);
								width: 0.8rem;
								height: 0.8rem;
								background: url(~@/assets/images/icon-audio.png) no-repeat;
								background-size: 100% auto;
								cursor: pointer;
							}
						}
						.keys-text {
							h4 {
								margin-bottom: 0.1rem;
								margin-top: 0.1rem;
								text-align: center;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								padding-left: 0.1rem;
								padding-right: 0.1rem;
							}
						}
					}
				}
			}
			.video {
				.newscontainer {
					padding-right: 0.3rem;
					padding-left: 0.3rem;
					margin-right: auto;
					margin-left: auto;
					.row {
						display: -webkit-box;
						margin-right: -0.3rem;
						margin-left: -0.3rem;

						.col-xs-12 {
							width: 100%;
							position: relative;
							padding-right: 0.3rem;
							padding-left: 0.3rem;
							min-height: 1px;
							.pic {
								position: relative;
								img {
									display: inline-block;
									vertical-align: middle;
									width: 100%;
									height: 3.47rem;
									border-radius: 12px;
								}
								span {
									position: absolute;
									left: 50%;
									top: 50%;
									-webkit-transform: translate(-50%, -50%);
									-moz-transform: translate(-50%, -50%);
									transform: translate(-50%, -50%);
									width: 1rem;
									height: 1rem;
									background: url(~@/assets/images/icon-video.png) no-repeat;
									background-size: 100% auto;
									cursor: pointer;
								}
							}
							h5 {
								font-weight: normal;
								margin: 0;
								padding: 0;
								a {
									font-size: 0.28rem;
									font-weight: bold;
									color: #2a2a2a;
									margin-top: 0.3rem;
								}
							}
							p {
								margin-top: 0.2rem;
								color: #787878;
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}
</style>
<style scoped>
</style>

<template>
  <div>
    <header class="header">
      <div class="newcontainer">
        <div class="row">
          <div class="col-xs-12 header-title">
            <h4 :title="detail.name">{{ detail.name }}</h4>
            <!-- <a @click="goPrev" class="back"><i></i></a> -->
            <a
              @click="favoritesFun(detail)"
              class="collect-pre"
              :class="{ nocollect: !favorites, collect: favorites }"
              ><i></i
            ></a>
          </div>
        </div>
      </div>
    </header>
    <div id="main">
      <!--内容区-->
      <div class="content-wrap">
        <!--视频播放-->
        <div class="video-play">
          <div class="newcontainer">
            <div class="row">
              <div class="col-xs-12">
                <a-player
                  ref="audioPlayer"
                  :music="music"
                  theme="#1057d1"
                  :showLrc="false"
                  v-if="flag"
                  preload="auto"
                ></a-player>
              </div>
            </div>
          </div>
        </div>
        <!--简介、作者-->
        <div class="detail-item">
          <ul id="myTabs" class="nav-tabs" role="tablist">
            <!-- <li role="presentation" class="active">
            <a>简介</a>
          </li>
          <li role="presentation">
            <a>作者</a>
          </li> -->
            <li
              v-for="(item, index) in TypeList"
              :class="item.class"
              :key="index"
              @click="TypeClick(item)"
            >
              <a>{{ item.name }}</a>
            </li>
          </ul>
          <div id="myTabContent" class="tab-content">
            <div class="newcontainer">
              <div class="row">
                <div class="col-xs-12">
                  <div v-if="type === '1'" class="tab-pane">
                    <template v-if="unfold">
                      <p class="all">
                        {{ detail.description }}
                      </p>
                      <div class="fold"><a @click="clickunfold()"></a></div>
                    </template>
                    <template v-if="!unfold">
                      <p class="little">
                        {{ detail.description }}
                      </p>
                      <div class="unfold"><a @click="clickunfold()"></a></div>
                    </template>
                  </div>
                  <div v-if="type === '2'" class="tab-pane">
                    <p>{{ detail.author }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="con-item more-info">
          <div class="newcontainer">
            <div class="title">/<span>更多信息</span>/</div>
            <div class="row">
              <div class="col-xs-12">
                <p>
                  出版社：<span>{{ detail.source }}</span>
                </p>
                <p>
                  出版时间：<span>{{ detail.dateissued }}</span>
                </p>
                <p>
                  时长：<span> {{ detail.audiotime }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--推荐听书-->
        <div class="con-item rec">
          <div class="videotitle">
            <div class="newcontainer">
              <div class="row">
                <div class="col-xs-12">/<span>推荐听书</span>/</div>
              </div>
            </div>
          </div>
          <div class="audio">
            <div class="newcontainer">
              <div class="row">
                <div
                  class="col-xs-6"
                  v-for="item in dataResource"
                  :key="item.doi"
                >
                  <div
                    class="audio-con"
                    @click="goDetail('MobileAudioDetail', item.doi)"
                  >
                    <div class="pic">
                      <img :src="item.imgurl" alt="" />
                      <span></span>
                    </div>
                    <a
                      :title="item.title"
                      v-html="item.name"
                      @click="goDetail('MobileAudioDetail', item.doi)"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- footer-->
        <div class="footer">
          <div class="newcontainer">
            <div class="row clearfix">
              <div class="col-xs-12">
                <p>吉林省惠民数字阅读平台</p>
                <p>©2021</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueAplayer from "vue-aplayer";
import { replacePath, replaceAudioPath, isMobile } from "@/utils/util";
import * as audioApi from "@/services/audio";
import { recommendDoiList } from "@/config/constConfig";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "AudioDetail",
  components: {
    "a-player": VueAplayer,
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  data() {
    return {
      TypeList: [
        {
          name: "简介",
          type: "1",
          class: "active",
        },
        {
          name: "作者",
          type: "2",
          class: "",
        },
      ],
      flag: false,
      unfold: false,
      type: "1",
      favorites: false,
      doi: this.$route.params.doi,
      detail: {},
      dataResource: [],
      music: {
        title: "音频播放",
        artist: "作者",
        src: "",
        pic: "",
      },
    };
  },
  watch: {
    $route(to) {
      this.doi = to.params.doi;
      this.initDetail();
      this.getIsFav();
    },
  },
  mounted() {},
  created() {
    this.Mobile();
    this.initDetail();
    this.initRecommend(recommendDoiList.detailAudioDoi);
    this.getIsFav();
  },
  methods: {
    Mobile() {
      if (!isMobile()) {
        this.$router.push({
          name: "AudioDetail",
          params: { doi: this.$route.params.doi },
        });
      }
    },
    ...mapMutations("account", ["saveNowRouter"]),
    clickunfold() {
      this.unfold = !this.unfold;
    },
    goDetail(name, doi) {
      this.$router.push({ name: name, params: { doi: doi } });
    },
    goPrev() {
      this.$router.go(-1);
    },
    goToView(path) {
      this.$router.push({ path: path });
    },
    TypeClick(item) {
      this.TypeList.forEach((element) => {
        element.class = "";
      });
      item.class = "active";
      this.type = item.type;
    },
    //获取是否已经收藏
    getIsFav() {
      if (!this.user || this.user === "") {
        return;
      }
      let obj = {
        doi: this.$route.params.doi,
        restype: "6",
        username: this.user,
      };
      audioApi.getIsFav(obj).then((res) => {
        if (res.success) {
          this.favorites = true;
        } else {
          this.favorites = false;
        }
      });
    },
    //收藏/取消收藏
    favoritesFun(item) {
      var vm = this;
      if (!this.user || this.user === "") {
        this.$confirm({
          title: "确认提示",
          content: `收藏需要先登录，是否确认登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            console.log("取消");
          },
        });
        // this.$notification["warning"]({
        //   message: "警告",
        //   description: "请先登录!",
        //   placement: "topLeft",
        //   style: {
        //     width: "500px",
        //   },
        // });
        return;
      }
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        this.$confirm({
          title: "确认提示",
          content: `登录已过期，该操作需重新登录，是否重新登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            console.log("取消");
          },
        });
        // this.$notification["warning"]({
        //   message: "警告",
        //   description: "toiken已过期，请重新登录再进行操作!",
        //   placement: "topLeft",
        //   style: {
        //     width: "500px",
        //   },
        // });
        return;
      }
      let obj = {
        booktype: "6",
        doi: this.$route.params.doi,
        name: item.name,
        operator: this.user,
      };
      audioApi.addFavoriteData(obj).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          // this.$notification["success"]({
          //   message: "成功",
          //   description: res.message,
          //   placement: "topLeft",
          //   style: {
          //     width: "500px",
          //   },
          // });
          this.favorites = !this.favorites;
        } else {
          this.$message.error(res.message);
          // this.$notification["warning"]({
          //   message: "警告",
          //   description: res.message,
          //   placement: "topLeft",
          //   style: {
          //     width: "500px",
          //   },
          // });
        }
      });
    },
    initRecommend(typeDoi) {
      audioApi
        .getRecommendByType({ page: 1, pageSize: 6, type: typeDoi })
        .then((res) => {
          const { success } = res;
          if (success) {
            const {
              data: { list },
            } = res;
            if (list.length % 2 !== 0) {
              list.pop();
            }
            this.dataResource = list.map((e) => {
              return {
                doi: e.sys_fld_doi,
                name: e.name,
                imgurl: replacePath(e.sys_fld_coverpath, "0", "1"),
                source: e.source,
              };
            });
          } else {
            this.dataResource = [];
          }
        });
    },
    //浏览记录
    addLog(item) {
      if (!this.user || this.user === "") {
        return;
      }
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        return;
      }
      let obj = {
        resourcetype: "6",
        resourcedoi: this.$route.params.doi,
        resourcename: item.name,
        username: this.user,
      };
      audioApi.addLog(obj);
    },
    initDetail() {
      this.$emit("bindActive", "mobileSearch");
      audioApi.getInfo(this.doi).then((res) => {
        const { success } = res;
        if (success) {
          this.flag = true;
          const {
            data: { data },
          } = res;
          this.detail = data;
          this.music.title = data.name;
          this.music.artist = data.author;
          this.music.src = replaceAudioPath(data.sys_fld_filepath);
          this.music.pic = replacePath(data.sys_fld_coverpath);
          this.addLog(this.detail);
        } else {
          this.detail = {};
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  //margin-top: 1.2rem;
  z-index: 1;
  top: 0;
  width: 100%;
  padding: 12px 0 2px 0;
  position: fixed;
  background-color: #f0f2f5;

  .newcontainer {
    width: 100%;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    margin-right: auto;
    margin-left: auto;
    .row {
      margin-right: -0.3rem;
      margin-left: -0.3rem;

      .header-title {
        position: relative;
        h4 {
          text-align: center;
          font-size: 0.28rem;
          color: #232323;
          font-weight: 600;
          padding: 0 0.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .back {
          position: absolute;
          left: 0.3rem;
          top: 0.08rem;
          font-size: 0.28rem;
          color: #fff;
          i {
            width: 0.2rem;
            height: 0.32rem;
            margin-top: -0.04rem;
            margin-right: 0.08rem;
            font-size: 0.28rem;
            color: #232323;
            background: url(~@/assets/images/icon-back.png) no-repeat;
            background-size: 100% auto;
            vertical-align: middle;
          }
        }
        .collect {
          position: absolute;
          right: 0.3rem;
          top: 0rem;
          i {
            width: 0.44rem;
            height: 0.48rem;
            background: url(~@/assets/images/icon-collect-pre.png) no-repeat;
            background-size: 100% auto;
          }
        }
        .nocollect {
          position: absolute;
          right: 0.3rem;
          top: 0rem;
          i {
            width: 0.44rem;
            height: 0.48rem;
            background: url(~@/assets/images/icon-collect.png) no-repeat;
            background-size: 100% auto;
          }
        }
      }
      .col-xs-12 {
        width: 100%;
      }
    }
  }
}
#main {
  margin-top: 0.85rem;
  .newcontainer {
    width: 100%;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    margin-right: auto;
    margin-left: auto;
  }

  .footer {
    padding: 0.3rem 0;
    margin-bottom: 1.5rem;
    .newcontainer {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
      .row {
        margin-right: -15px;
        margin-left: -15px;
        .col-xs-12 {
          width: 100%;
          padding-right: 15px;
          padding-left: 15px;
          p {
            text-align: center;
            font-size: 0.24rem;
            color: #333333;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .content-wrap {
    height: 100%;
    .video-play {
      background: #fff;
      .row {
        margin-right: -0.3rem;
        margin-left: -0.3rem;

        .col-xs-12 {
          position: relative;
          padding: 0px;
          .aplayer {
            padding: 0.3rem;
          }
        }
      }
    }
    .detail-item {
      /*简介、作者*/
      .nav-tabs {
        background: #f1f1f1;
        padding: 0.12rem 0;
        height: 0.8rem;
        text-align: center;
        border-bottom: none;
        li {
          float: left;
          width: 50%;
          text-align: center;
          border-right: 1px solid #d4d4d3;
          a {
            display: inline-block;
            font-size: 0.28rem;
            color: #666666;
            width: 40%;
            padding: 0.1rem 0;
            border-bottom: 2px solid;
            border-color: transparent;
            margin: -0.24rem 0;
          }
        }
        li:last-child {
          border-right: 0px;
        }
        li.active {
          a {
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 2px solid #d4000e;
            color: #1d1d1d;
            font-weight: bold;
            background-color: transparent;
          }
          a:focus {
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 2px solid #d4000e;
            color: #1d1d1d;
            font-weight: bold;
            background-color: transparent;
          }
          a:hover {
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 2px solid #d4000e;
            color: #1d1d1d;
            font-weight: bold;
            background-color: transparent;
          }
        }
      }
      .tab-content {
        background: #fff;
        padding: 0.3rem 0;
        .tab-pane {
          p {
            text-indent: 2em;
            font-size: 0.24rem;
            line-height: 0.48rem;
            margin-bottom: 0px;
          }
          p.little {
            -webkit-line-clamp: 5;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .unfold {
            text-align: center;
            margin-top: 0.3rem;
            a {
              text-align: center;
              width: 0;
              height: 0;
              border-left: 0.2rem solid transparent;
              border-right: 0.2rem solid transparent;
              border-top: 0.2rem solid #bababa;
            }
          }
          .fold {
            text-align: center;
            margin-top: 0.3rem;
            a {
              text-align: center;
              width: 0;
              height: 0;
              border-width: 0 0.2rem 0.2rem;
              border-style: solid;
              border-color: transparent transparent #bababa;
            }
          }
        }
      }
    }
    .con-item {
      margin-top: 0.1rem;
      background: #fff;
      padding: 0.2rem 0;
      .videotitle {
        padding-bottom: 0;
        text-align: center;
        font-size: 0.28rem;
        color: #b6b6b6;
        margin-bottom: 0.2rem;
        font-weight: bold;
        border-bottom: none;
        span {
          color: #333333;
          margin: 0 0.32rem;
          font-size: 0.24rem;
        }
      }

      /*推荐-音频*/
      .audio {
        .row {
          margin-right: -0.3rem;
          margin-left: -0.3rem;
          .col-xs-6 {
            margin-bottom: 0.3rem;
            display: inline-block;
            width: 50%;
            position: relative;
            min-height: 1px;
            padding-right: 0.3rem;
            padding-left: 0.3rem;
            .audio-con {
              background: #fff;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.175);
              .pic {
                position: relative;
                img {
                  width: 100%;
                  height: 2.36rem;
                }
                span {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  -webkit-transform: translate(-50%, -50%);
                  -moz-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
                  width: 1.2rem;
                  height: 1.2rem;
                  background: url(~@/assets/images/icon-audio.png) no-repeat;
                  background-size: 100% auto;
                  cursor: pointer;
                }
              }
              a {
                width: 100%;
                font-size: 0.28rem;
                font-weight: bold;
                color: #2a2a2a;
                padding: 0.28rem;
                text-overflow: ellipsis;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;
                line-height: 0.36rem;
              }
            }
          }
        }
      }
    }
    .more-info {
      .title {
        text-align: center;
        font-size: 0.28rem;
        color: #b6b6b6;
        margin-bottom: 0.2rem;
        font-weight: bold;
        span {
          color: #333333;
          margin: 0 0.32rem;
          font-size: 0.28rem;
        }
      }
      p {
        font-size: 0.24rem;
        line-height: 0.56rem;
        margin-bottom: 0;
      }
    }
  }
}
</style>



<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 header-title">
            <h4>{{ detail.title }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="content-wrap ql-snow">
        <div class="backclass"></div>
        <div class="title-content">
          <h4>{{ detail.title }}</h4>
          <span>{{ detail.pubdate }}</span>
        </div>
        <div class="content editor quill-editor ql-editor" v-html="detail.content"></div>
        <div class="link">
          <template v-if="previd != null && previd != ''">
            <p>
              <a @click="PrevAndNext('MobileNewsDetail', previd)"
                >上一篇：{{ prevtitle }}</a
              >
            </p>
          </template>
          <template v-if="nextid != null && nextid != ''">
            <p>
              <a @click="PrevAndNext('MobileNewsDetail', nextid)"
                >下一篇：{{ nexttitle }}</a
              >
            </p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
import * as Api from "@/services/activity";
import { silceTime, isMobile } from "@/utils/util";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "News",
  components: {},
  data() {
    return {
      doi: this.$route.params.doi,
      type: this.$route.params.type,
      detail: {},
      nextid: "",
      previd: "",
      nexttitle: "",
      prevtitle: "",
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    this.Mobile();
    this.initDetail();
    this.initPrevAndNext();
  },
  watch: {
    $route(to) {
      this.doi = to.params.doi;
      this.initDetail();
      this.initPrevAndNext();
    },
  },
  methods: {
    Mobile() {
      if (!isMobile()) {
        this.$router.push({
          name: "newsDetail",
          params: {
            doi: this.$route.params.doi,
            type: this.$route.params.type,
          },
        });
      }
    },
    PrevAndNext(name, doi) {
      this.$router.push({ name: name, params: { doi: doi, type: this.type } });
      // this.doi = doi;
      // this.initDetail();
      // this.initPrevAndNext();
    },
    goToView(path) {
      this.$router.push({ path: path });
    },
	 ...mapMutations("account", ["saveNowRouter"]),
    initDetail() {
      this.$emit("bindActive", "mobileNews");
      Api.getInfo(this.doi).then((res) => {
        const { success } = res;
        //console.log(res)
        if (success) {
          const {
            data: { data },
          } = res;
          this.detail = data;
          if (this.detail.isloginlook === "1") {
            var vm = this;
            if (!this.user || this.user === "") {
              this.$confirm({
                title: "确认提示",
                content: `登录后即可阅读，是否确认登录？`,
                okType: "warning",
                onOk() {
                  vm.saveNowRouter(vm.$route);
                  vm.$router.push({ name: "Login" });
                },
                onCancel() {
                   vm.$router.push({ name: "mobileNews" });
                },
              });
              return;
            }
            if (
              localStorage.getItem("expireAt") &&
              new Date().getTime() >=
                new Date(localStorage.getItem("expireAt")).getTime()
            ) {
              this.$confirm({
                title: "确认提示",
                content: `登录已过期，该操作需重新登录，是否重新登录？`,
                okType: "warning",
                onOk() {
                  vm.saveNowRouter(vm.$route);
                  vm.$router.push({ name: "Login" });
                },
                onCancel() {
                  vm.$router.push({ name: "mobileNews" });
                },
              });
              return;
            }
          }
          this.detail.pubdate = silceTime(this.detail.pubdate);
        } else {
          this.detail = {};
        }
      });
    },
    initPrevAndNext() {
      Api.getPrevAndNextById(this.doi).then((res) => {
        let data = res;
        if (data.success) {
          this.nextid = data.data.nextid;
          this.previd = data.data.previd;
          this.nexttitle = data.data.nexttitle;
          this.prevtitle = data.data.prevtitle;
        } else {
          this.detail = {};
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  //margin-top: 1.2rem;
  z-index: 1;
  top: 0;
  width: 100%;
  padding: 12px 0 2px 0;
  position: fixed;
  background-color: #f0f2f5;
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    .row {
      margin-right: -15px;
      margin-left: -15px;
      .header-title {
        position: relative;
        h4 {
          text-align: center;
          font-size: 0.28rem;
          color: #232323;
          font-weight: 600;
          padding: 0 1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .back {
          position: absolute;
          left: 0.3rem;
          top: 0.08rem;
          font-size: 0.28rem;
          color: #fff;
          i {
            width: 0.2rem;
            height: 0.4rem;
            margin-top: -0.04rem;
            margin-right: 0.08rem;
            font-size: 0.28rem;
            color: #232323;
            background: url(~@/assets/images/icon-back.png) no-repeat;
            background-size: 100% auto;
            vertical-align: middle;
          }
        }
        .collect {
          position: absolute;
          right: 0.3rem;
          top: 0rem;
          i {
            width: 0.44rem;
            height: 0.48rem;
            background: url(~@/assets/images/icon-collect-pre.png) no-repeat;
            background-size: 100% auto;
          }
        }
        .nocollect {
          position: absolute;
          right: 0.3rem;
          top: 0rem;
          i {
            width: 0.44rem;
            height: 0.48rem;
            background: url(~@/assets/images/icon-collect.png) no-repeat;
            background-size: 100% auto;
          }
        }
      }
      .col-xs-12 {
        width: 100%;
      }
    }
  }
}
.main {
  width: 100%;
  background-color: #fff;
  .content-wrap {
    margin-top: 0.85rem;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    .title-content {
      text-align: center;
      margin: 0 auto;
      border-bottom: 1px solid #e2e2e2;
      h4 {
        font-size: 0.28rem;
        margin-bottom: 0;
      }
      .back {
        font-size: 0.32rem;
        color: #fff;
        float: left;
        i {
          width: 0.2rem;
          height: 0.32rem;
          margin-top: -0.04rem;
          margin-right: 0.08rem;
          font-size: 0.28rem;
          color: #232323;
          background: url(~@/assets/images/icon-back.png) no-repeat;
          background-size: 100% auto;
          vertical-align: middle;
        }
      }
      span {
        width: 100%;
        text-align: center;
        font-size: 0.28rem;
        color: #8f8f8f;
        margin-bottom: 0.1rem;
        margin-top: 0.1rem;
      }
    }
    .content {
      margin-top: 0.3rem;
      font-size: 0.24rem !important;
      border-bottom: 1px solid #e2e2e2;
      // p {
      //   text-indent: 2em;
      img {
        width: 100% !important;
        height: 2rem !important;
      }
      //}
    }
    .link {
      margin-bottom: 2rem;
      margin-top: 0.28rem;
      a {
        color: #b93e47;
        font-size: 0.28rem;
      }
    }
  }
}
</style>
<style scoped>
.content >>> p img {
  width: 80% !important;
  height: auto !important;
}
.content >>> p {
  text-indent: 2em;
  line-height: 0.48rem;
}
.content >>> p strong {
  display: initial;
}
/* .content >>> .ql-align-justify {
  text-indent: 2em;
} */
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"main-item position"},[_c('a',{on:{"click":function($event){return _vm.goToView('/home/index')}}},[_vm._v("首页")]),_vm._v(" > "),_c('span',[_vm._v("新闻资讯")])]),_c('div',{staticClass:"main-item"},[_c('div',[_c('div',{staticClass:"box"},[_vm._m(0),_c('a-list',{staticClass:"news-list",attrs:{"item-layout":"vertical","data-source":_vm.newDataList,"pagination":{
            current: _vm.pagination.onpage,
            pageSize: _vm.pagination.pageSize,
            total: _vm.pagination.total,
            showSizeChanger: false,
            showLessItems: true,
            showQuickJumper: true,
            onChange: _vm.onChange,
            onShowSizeChange: _vm.onSizeChange,
          }},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{key:item.id},[_c('h4',[_c('a',{attrs:{"title":item.title},on:{"click":function($event){return _vm.goRouter('newsDetail', item.urlid)}}},[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"news clearfix"},[_c('a',{staticClass:"news-pic fl",on:{"click":function($event){return _vm.goRouter('newsDetail', item.urlid)}}},[_c('img',{attrs:{"src":item.sys_fld_coverpath}})]),_c('div',{staticClass:"news-info fr"},[_c('p',[_vm._v(" "+_vm._s(item.digest)+" ")]),_c('div',[_c('span',[_vm._v(_vm._s(item.pubdate))]),_c('a',{on:{"click":function($event){return _vm.goRouter('newsDetail', item.urlid)}}},[_vm._v("查看详情")])])])])])}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('span',[_c('i'),_vm._v("新闻资讯")])])}]

export { render, staticRenderFns }
import { request, METHOD } from "@/utils/request";
import { addurl } from "@/utils/util";
import { requestApiUrl } from "@/config/constConfig";
/**
 * 获取图书列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param order 排序字段
 */
 export async function getBookList(params) {
    return request(
      addurl("getList", requestApiUrl.requestBook) +
        "/" +
        params.page +
        "/" +
        params.size,
      METHOD.GET,
      {
        online: "1",
        query: params.query,
      }
    );
  }
  /**
 * 获取听书列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param keyword
 * @param type p-获取部分字段
 */
export async function getAudioList(params) {
    return request(
      addurl("getList", requestApiUrl.requestAudio) +
        "/" +
        params.page +
        "/" +
        params.size,
      METHOD.GET,
      {
        query: params.query,
        online: 1,
      }
    );
  }

  /**
 * 获取视频列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 */
 export async function getvideoList(params) {
    return request(
      addurl("getList", requestApiUrl.requestVideo) +
        "/" +
        params.page +
        "/" +
        params.size,
      METHOD.GET,
      {
        online: "1",
        query: params.query,
      }
    );
  }

  /**
 * 获取期刊列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 */
 export async function getjournalList(params) {
    return request(
      addurl("getList", requestApiUrl.requestJdrpJournal) +
        "/" +
        params.page +
        "/" +
        params.size,
      METHOD.GET,
      {
        type: "0",
        query: params.query,
      }
    );
  }

  /**
 * 获取新闻列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param type p-获取部分字段
 * @param typeid 新闻类型
 */
export async function getNewsList(params) {
    return request(
      addurl("getList", requestApiUrl.requestNews) +
        "/" +
        params.page +
        "/" +
        params.size,
      METHOD.GET,
      {
        query: params.query,
        typeid: params.type,
        online: 1,
      }
    );
  }
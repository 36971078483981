<template>
  <div>
    <header class="header">
      <div class="newcontainer">
        <div class="row">
          <div class="col-xs-12 header-title">
            <h4 :title="detail.name">{{ detail.name }}</h4>
            <!-- <a @click="goPrev" class="back"><i></i></a> -->
            <a
              @click="favoritesFun(detail)"
              class="collect-pre"
              :class="{ nocollect: !favorites, collect: favorites }"
              ><i></i
            ></a>
          </div>
        </div>
      </div>
    </header>
    <div id="main">
      <!--内容区-->
      <div class="content-wrap">
        <!--视频播放-->
        <div class="video-play">
          <div class="newcontainer">
            <div class="row">
              <div class="col-xs-12">
                <video-player
                  class="main-item video-player"
                  ref="videoPlayer"
                  :playsinline="true"
                  :options="playerOptions"
                >
                </video-player>
                <!-- <video
                  v-if="mobile == 'iPhone'"
                  id="video"
                  width="100%"
                  height="100%"
                  controls
                  preload="none"
                  webkit-playsinline
                  playsinline="true"
                  :poster="videoImg"
                  :src="videoSrc"
                ></video> -->
              </div>
            </div>
          </div>
        </div>
        <!--简介、作者-->
        <div class="detail-item">
          <ul id="myTabs" class="nav-tabs" role="tablist">
            <!-- <li role="presentation" class="active">
            <a>简介</a>
          </li>
          <li role="presentation">
            <a>作者</a>
          </li> -->
            <li
              v-for="(item, index) in TypeList"
              :class="item.class"
              :key="index"
              @click="TypeClick(item)"
            >
              <a>{{ item.name }}</a>
            </li>
          </ul>
          <div id="myTabContent" class="tab-content">
            <div class="newcontainer">
              <div class="row">
                <div class="col-xs-12">
                  <div v-if="type === '1'" class="tab-pane">
                    <template v-if="unfold">
                      <p class="all">
                        {{ detail.description }}
                      </p>
                      <div class="fold"><a @click="clickunfold()"></a></div>
                    </template>
                    <template v-if="!unfold">
                      <p class="little">
                        {{ detail.description }}
                      </p>
                      <div class="unfold"><a @click="clickunfold()"></a></div>
                    </template>
                  </div>
                  <div v-if="type === '2'" class="tab-pane">
                    <p>{{ detail.author }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--推荐视频-->
        <div class="con-item rec">
          <div class="videotitle">
            <div class="newcontainer">
              <div class="row">
                <div class="col-xs-12">/<span>推荐视频</span>/</div>
              </div>
            </div>
          </div>
          <div class="video">
            <div class="newcontainer">
              <ul>
                <li
                  v-for="(item, index) in dataResource"
                  :key="index"
                  @click="goDetail('MobileVideoDetail', item.doi)"
                >
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="pic">
                        <img :src="item.imgurl" alt="" />
                        <span></span>
                      </div>
                      <h5>
                        <a @click="goDetail('MobileVideoDetail', item.doi)">{{
                          item.name
                        }}</a>
                      </h5>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- footer-->
        <div class="footer">
          <div class="newcontainer">
            <div class="row clearfix">
              <div class="col-xs-12">
                <p>吉林省惠民数字阅读平台</p>
                <p>©2021</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import * as Api from "@/services/video";
import { replacePath, replaceVideoPath, isMobile,replaceISOVideoPath } from "@/utils/util";
import { recommendDoiList } from "@/config/constConfig";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "VideoDetail",
  components: { videoPlayer },
  data() {
    return {
      TypeList: [
        {
          name: "简介",
          type: "1",
          class: "active",
        },
        {
          name: "作者",
          type: "2",
          class: "",
        },
      ],
      //苹果使用video
      //mobile: "",
      //videoSrc: "",
      //videoImg: "",

      unfold: false,
      type: "1",
      favorites: false,
      doi: this.$route.params.doi,
      detail: {},
      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: "", //你的封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
      dataResource: [],
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  watch: {
    $route(to) {
      this.doi = to.params.doi;
      this.initDetail();
      this.getIsFav();
    },
  },
  mounted() {},
  created() {
    this.Mobile();
    this.initDetail();
    this.initRecommend(recommendDoiList.detailVideoDoi);
    //查询是否收藏
    this.getIsFav();
    this.text = navigator.appVersion;
  },
  methods: {
    ...mapMutations("account", ["saveNowRouter"]),
    Mobile() {
      if (!isMobile()) {
        this.$router.push({
          name: "videoDetail",
          params: { doi: this.$route.params.doi },
        });
      }
    },
    goPrev() {
      this.$router.go(-1);
    },
    //是否已经收藏
    getIsFav() {
      if (!this.user || this.user === "") {
        return;
      }
      let obj = {
        doi: this.$route.params.doi,
        restype: "5",
        username: this.user,
      };
      Api.getIsFav(obj).then((res) => {
        if (res.success) {
          this.favorites = true;
        } else {
          this.favorites = false;
        }
      });
    },
    //收藏/取消收藏
    favoritesFun(item) {
      var vm = this;
      if (!this.user || this.user === "") {
        this.$confirm({
          title: "确认提示",
          content: `收藏需要先登录，是否确认登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            console.log("取消");
          },
        });
        // this.$notification["warning"]({
        //   message: "警告",
        //   description: "请先登录!",
        //   placement: "topLeft",
        //   style: {
        //     width: "500px",
        //   },
        // });
        return;
      }
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        this.$confirm({
          title: "确认提示",
          content: `登录已过期，该操作需重新登录，是否重新登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            console.log("取消");
          },
        });
        // this.$notification["warning"]({
        //   message: "警告",
        //   description: "toiken已过期，请重新登录再进行操作!",
        //   placement: "topLeft",
        //   style: {
        //     width: "500px",
        //   },
        // });
        return;
      }
      let obj = {
        booktype: "5",
        doi: this.$route.params.doi,
        name: item.name,
        operator: this.user,
      };
      Api.addFavoriteData(obj).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          // this.$notification["success"]({
          //   message: "成功",
          //   description: res.message,
          //   placement: "topLeft",
          //   style: {
          //     width: "500px",
          //   },
          // });
          this.favorites = !this.favorites;
        } else {
          this.$message.error(res.message);
          // this.$notification["warning"]({
          //   message: "警告",
          //   description: res.message,
          //   placement: "topLeft",
          //   style: {
          //     width: "500px",
          //   },
          // });
        }
      });
    },
    //浏览记录
    addLog(item) {
      if (!this.user || this.user === "") {
        return;
      }
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        return;
      }
      let obj = {
        resourcetype: "5",
        resourcedoi: this.$route.params.doi,
        resourcename: item.name,
        username: this.user,
      };
      Api.addLog(obj);
    },
    clickunfold() {
      this.unfold = !this.unfold;
    },
    TypeClick(item) {
      this.TypeList.forEach((element) => {
        element.class = "";
      });
      item.class = "active";
      this.type = item.type;
    },
    goDetail(name, doi) {
      this.$router.push({ name: name, params: { doi: doi } });
    },
    goToView(path) {
      this.$router.push({ path: path });
    },
    initRecommend(typeDoi) {
      Api.getRecommendByType({ page: 1, pageSize: 4, type: typeDoi }).then(
        (res) => {
          const { success } = res;
          if (success) {
            const {
              data: { list },
            } = res;
            this.dataResource = list.map((e) => {
              return {
                doi: e.sys_fld_doi,
                name: e.name,
                imgurl: replacePath(e.sys_fld_coverpath, "0", "1"),
                source: e.source,
              };
            });
          } else {
            this.dataResource = [];
          }
        }
      );
    },
    initDetail() {
      this.$emit("bindActive", "mobileSearch");
      let _this = this;
      Api.getInfo(this.doi).then((res) => {
        const { success } = res;
        //console.log(res)
        if (success) {
          const {
            data: { data },
          } = res;
          _this.detail = data;
          let isphone =
            navigator.appVersion.indexOf("iPhone") !== -1
              ? "iPhone"
              : "android";
            _this.detail.sys_fld_coverpath = replacePath(
              data.sys_fld_coverpath,
              "1"
            );
            _this.detail.sys_fld_filepath =isphone === "android"? replaceVideoPath(
              data.sys_fld_filepath
            ):replaceISOVideoPath(data.sys_fld_filepath);

            _this.playerOptions.sources = [];
            _this.playerOptions.sources.push(
              {
                src: _this.detail.sys_fld_filepath, // 路径
                type: "video/mp4", // 类型
              },
              {
                src: _this.detail.sys_fld_filepath, // 路径
                type: "video/ogg", // 类型
              },
              {
                src: _this.detail.sys_fld_filepath, // 路径
                type: "video/webm", // 类型
              }
            );
            //console.log(_this.playerOptions)
            _this.playerOptions.poster = _this.detail.sys_fld_coverpath; //你的封面地址
            //console.log(_this.playerOptions)
            this.addLog(_this.detail);
          // } else {
          //   console.log("video");
          //   this.videoImg = replacePath(_this.detail.sys_fld_coverpath, "1");
          //   this.videoSrc = replaceISOVideoPath(_this.detail.sys_fld_filepath);
          //   console.log(this.videoSrc)
          // }
        } else {
          _this.detail = {};
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  //margin-top: 1.2rem;
  z-index: 1;
  top: 0;
  width: 100%;
  padding: 12px 0 2px 0;
  position: fixed;
  background-color: #f0f2f5;

  .newcontainer {
    width: 100%;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    margin-right: auto;
    margin-left: auto;
    .row {
      margin-right: -0.3rem;
      margin-left: -0.3rem;
      .header-title {
        position: relative;
        h4 {
          text-align: center;
          font-size: 0.28rem;
          color: #232323;
          font-weight: 600;
          padding: 0 0.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .back {
          position: absolute;
          left: 0.3rem;
          top: 0.08rem;
          font-size: 0.28rem;
          color: #fff;
          i {
            width: 0.2rem;
            height: 0.32rem;
            margin-top: -0.04rem;
            margin-right: 0.08rem;
            font-size: 0.28rem;
            color: #232323;
            background: url(~@/assets/images/icon-back.png) no-repeat;
            background-size: 100% auto;
            vertical-align: middle;
          }
        }
        .collect {
          position: absolute;
          right: 0.3rem;
          top: 0rem;
          i {
            width: 0.44rem;
            height: 0.48rem;
            background: url(~@/assets/images/icon-collect-pre.png) no-repeat;
            background-size: 100% auto;
          }
        }
        .nocollect {
          position: absolute;
          right: 0.3rem;
          top: 0rem;
          i {
            width: 0.44rem;
            height: 0.48rem;
            background: url(~@/assets/images/icon-collect.png) no-repeat;
            background-size: 100% auto;
          }
        }
      }
      .col-xs-12 {
        width: 100%;
      }
    }
  }
}
#main {
  margin-top: 0.85rem;
  .newcontainer {
    width: 100%;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    margin-right: auto;
    margin-left: auto;
  }

  .footer {
    padding: 0.3rem 0;
    margin-bottom: 1.5rem;
    .newcontainer {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
      .row {
        margin-right: -15px;
        margin-left: -15px;
        .col-xs-12 {
          width: 100%;
          padding-right: 15px;
          padding-left: 15px;
          p {
            text-align: center;
            font-size: 0.24rem;
            color: #333333;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .content-wrap {
    height: 100%;
    .video-play {
      .row {
        margin-right: -0.3rem;
        margin-left: -0.3rem;

        .col-xs-12 {
          position: relative;
          padding: 0px;
        }
      }
    }
    .detail-item {
      /*简介、作者*/
      .nav-tabs {
        background: #f1f1f1;
        padding: 0.12rem 0;
        height: 0.8rem;
        text-align: center;
        border-bottom: none;
        li {
          float: left;
          width: 50%;
          text-align: center;
          border-right: 1px solid #d4d4d3;
          a {
            display: inline-block;
            font-size: 0.28rem;
            color: #666666;
            width: 40%;
            padding: 0.1rem 0;
            border-bottom: 2px solid;
            border-color: transparent;
            margin: -0.24rem 0;
          }
        }
        li:last-child {
          border-right: 0px;
        }
        li.active {
          a {
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 2px solid #d4000e;
            color: #1d1d1d;
            font-weight: bold;
            background-color: transparent;
          }
          a:focus {
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 2px solid #d4000e;
            color: #1d1d1d;
            font-weight: bold;
            background-color: transparent;
          }
          a:hover {
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 2px solid #d4000e;
            color: #1d1d1d;
            font-weight: bold;
            background-color: transparent;
          }
        }
      }
      .tab-content {
        background: #fff;
        padding: 0.1rem 0;
        .tab-pane {
          p {
            text-indent: 2em;
            font-size: 0.24rem;
            line-height: 0.56rem;
            margin-bottom: 0px;
          }
          p.little {
            -webkit-line-clamp: 5;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .unfold {
            text-align: center;
            margin-top: 0.3rem;
            a {
              text-align: center;
              width: 0;
              height: 0;
              border-left: 0.2rem solid transparent;
              border-right: 0.2rem solid transparent;
              border-top: 0.2rem solid #bababa;
            }
          }
          .fold {
            text-align: center;
            margin-top: 0.3rem;
            a {
              text-align: center;
              width: 0;
              height: 0;
              border-width: 0 0.2rem 0.2rem;
              border-style: solid;
              border-color: transparent transparent #bababa;
            }
          }
        }
      }
    }
    /*推荐视频*/
    .con-item {
      margin-top: 0.1rem;
      background: #fff;
      padding: 0.2rem 0;
      .videotitle {
        padding-bottom: 0;
        text-align: center;
        font-size: 0.28rem;
        color: #b6b6b6;
        margin-bottom: 0.1rem;
        font-weight: bold;
        border-bottom: none;
        span {
          color: #333333;
          margin: 0 0.32rem;
        }
      }
      /*推荐-视频*/
      .video {
        ul {
          li {
            padding: 0.3rem 0.3rem 0.4rem;
            background: #fff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.175);
            margin-bottom: 0.3rem;
            border-radius: 6px;
            .row {
              margin-right: -0.3rem;
              margin-left: -0.3rem;

              .col-xs-12 {
                position: relative;
                min-height: 1px;
                padding-right: 0.3rem;
                padding-left: 0.3rem;

                .pic {
                  width: 100%;
                  position: relative;
                  height: 3.16rem;
                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: 12px;
                  }
                  span {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    width: 1.2rem;
                    height: 1.2rem;
                    background: url(~@/assets/images/icon-video.png) no-repeat;
                    background-size: 100% auto;
                    cursor: pointer;
                  }
                }
                h5 {
                  margin-bottom: 0px;
                  a {
                    font-size: 0.28rem;
                    font-weight: bold;
                    color: #2a2a2a;
                    margin-top: 0.3rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style  scoped>
.video-play >>> .video-player {
  padding-top: 0;
}
.video-play >>> .video-player .vjs-poster {
  background-size: 100% 100%;
}
.video-play >>> .video-player .video-js .vjs-big-play-button {
  left: 43%;
  top: 35%;
  width: 1.2em;
  height: 1.2em;
  line-height: 1.1em;
  border-radius: 3em;
  font-size: 5em;
}

@media screen and (min-width: 760px) {
  .video-play >>> .video-player .video-js .vjs-big-play-button {
    left: 45%;
    top: 40%;
    width: 1.2em;
    height: 1.2em;
    line-height: 1.1em;
    border-radius: 3em;
    font-size: 7em;
  }
}
</style>


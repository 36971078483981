var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"main"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"main-item clearfix"},[_c('div',{staticClass:"left-nav fl"},[_c('div',{staticClass:"user-info"},[_vm._m(0),_c('p',[_vm._v(_vm._s(_vm.user))])]),_c('ul',_vm._l((_vm.MenuList),function(item,index){return _c('li',{key:index,class:item.type},[_c('a',{class:item.class,on:{"click":function($event){return _vm.MenuClick(item)}}},[_c('i'),_vm._v(_vm._s(item.name))])])}),0)]),_c('div',{staticClass:"right-con fr"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"title"},[_c('span',[_c('i'),_vm._v(" "+_vm._s(_vm.selectedMenutype == "collect" ? "我的收藏" : _vm.selectedMenutype == "footprint" ? "我的足迹" : "修改密码")+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showbody),expression:"showbody"}],staticClass:"tab"},[_vm._l((_vm.TypeList),function(item,index){return _c('a',{key:index,class:item.class,attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.TypeClick(item)}}},[_vm._v(_vm._s(item.name))])}),_c('span',[_vm._v("共"),_c('b',[_vm._v(_vm._s(_vm.pagination.total))]),_vm._v("个"+_vm._s(_vm.selectedtype == "book" ? "图书" : _vm.selectedtype == "audio" ? "听书" : "视频"))])],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showbody),expression:"showbody"}],staticClass:"res-list res-list-1"},[_c('a-list',{staticClass:"book-list clearfix",attrs:{"grid":{ gutter: 30, column: 3 },"item-layout":"vertical","data-source":_vm.dataResource,"pagination":{
                    current: _vm.pagination.onpage,
                    pageSize: _vm.pagination.pageSize,
                    total: _vm.pagination.total,
                    showSizeChanger: false,
                    showLessItems: true,
                    showQuickJumper: true,
                    onChange: _vm.onChange,
                    onShowSizeChange: _vm.onSizeChange,
                  }},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return (_vm.selectedtype === 'book')?_c('a-list-item',{key:item.doi,staticClass:"book"},[_c('div',{staticClass:"pic",attrs:{"title":item.name}},[_c('a',{on:{"click":function($event){return _vm.goDetail('bookDetail', item.doi)}}},[_c('img',{attrs:{"src":item.img,"alt":""}})]),_c('span',{attrs:{"title":'删除'},on:{"click":function($event){return _vm.deleteMessage(item, 2)}}},[_vm._v(">")])]),_c('div',{staticClass:"text"},[_c('h4',{attrs:{"title":item.name}},[_c('a',{on:{"click":function($event){return _vm.goDetail('bookDetail', item.doi)}}},[_vm._v(_vm._s(item.name))])]),_c('p',[_vm._v(_vm._s(item.issuedep))])])]):(
                      _vm.selectedtype === 'audio' || _vm.selectedtype === 'video'
                    )?_c('a-list-item',{key:item.doi,staticClass:"video"},[_c('div',{staticClass:"pic",attrs:{"title":item.name}},[_c('a',{on:{"click":function($event){return _vm.goDetail(
                            _vm.selectedtype === 'video'
                              ? 'videoDetail'
                              : 'AudioDetail',
                            item.doi
                          )}}},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('div',{staticClass:"icon-play"},[(_vm.selectedtype === 'audio')?_c('img',{attrs:{"src":require("@/assets/images/btn-play.png")},on:{"click":function($event){return _vm.goDetail('AudioDetail', item.doi)}}}):_vm._e(),(_vm.selectedtype === 'video')?_c('img',{attrs:{"src":require("@/assets/images/icon-play.png")},on:{"click":function($event){return _vm.goDetail(
                                _vm.selectedtype === 'video' ? 'videoDetail' : '',
                                item.doi
                              )}}}):_vm._e()])]),_c('span',{attrs:{"title":'删除'},on:{"click":function($event){return _vm.deleteMessage(item, 1)}}})]),_c('div',{staticClass:"text"},[_c('h4',{attrs:{"title":item.name}},[_c('a',{on:{"click":function($event){return _vm.goDetail(
                              _vm.selectedtype === 'video'

                                ? 'videoDetail'
                                : 'AudioDetail',
                              item.doi
                            )}}},[_vm._v(_vm._s(item.name))])]),_c('p',[_vm._v(_vm._s(item.issuedep))])])]):_vm._e()}}],null,true)})],1),(!_vm.showbody)?[_c('update-pass-word')]:_vm._e()],2)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('img',{attrs:{"src":require("@/assets/images/user-photo.png"),"alt":""}})])}]

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"ser-nav"},[_c('div',{staticClass:"main-con"},_vm._l((_vm.titleData),function(item,index){return _c('a',{key:index,class:{ active: item.active },on:{"click":function($event){return _vm.changeTab(index, item.type)}}},[_vm._v(_vm._s(item.name)),_c('i')])}),0)]),_c('div',{attrs:{"id":"main"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"box"},[_vm._m(0),_c('div',{staticClass:"tab"},[_c('span',[_vm._v("共"),_c('b',[_vm._v(_vm._s(_vm.count))]),_vm._v(" 条结果")])]),(_vm.type === 'book' || _vm.type === 'periodical')?_c('div',{staticClass:"main-item clearfix book bookStyle"},[_c('a-list',{attrs:{"item-layout":"vertical","data-source":_vm.newDataList,"pagination":{
              current: _vm.pagination.onpage,
              pageSize: _vm.pagination.pageSize,
              total: _vm.pagination.total,
              showSizeChanger: false,
              showLessItems: true,
              showQuickJumper: true,
              onChange: _vm.onChange,
              onShowSizeChange: _vm.onSizeChange,
            }},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{key:item.sys_fld_doi},[_c('div',{staticClass:"res-list res-list-1 show"},[_c('ul',{staticClass:"book-list clearfix"},[_c('li',[_c('div',{staticClass:"pic"},[_c('a',[(_vm.type === 'book')?_c('img',{attrs:{"src":item.sys_fld_coverpath,"title":item.name},on:{"click":function($event){return _vm.goRouter('bookDetail', item.sys_fld_doi)}}}):_vm._e()]),_c('a',[(_vm.type === 'periodical')?_c('img',{attrs:{"src":item.coverpath,"title":item.name},on:{"click":function($event){return _vm.goPage(item.visitelink)}}}):_vm._e()])]),_c('div',{staticClass:"text"},[_c('h4',[(_vm.type === 'book')?_c('a',{attrs:{"title":item.name},domProps:{"innerHTML":_vm._s(item.name)},on:{"click":function($event){return _vm.goRouter('bookDetail', item.sys_fld_doi)}}}):_vm._e(),(_vm.type === 'periodical')?_c('a',{attrs:{"title":item.name},domProps:{"innerHTML":_vm._s(item.name)},on:{"click":function($event){return _vm.goPage(item.visitelink)}}}):_vm._e()]),(_vm.type === 'book')?_c('p',[_vm._v(_vm._s(item.issuedep))]):_vm._e(),(_vm.type === 'periodical')?_c('p',[_vm._v(_vm._s(item.description))]):_vm._e()])])])])])}}],null,false,4039602248)})],1):_vm._e(),(_vm.type === 'audio' || _vm.type === 'radio')?_c('div',{staticClass:"main-item clearfix book vodio"},[_c('a-list',{attrs:{"item-layout":"vertical","data-source":_vm.newDataList,"pagination":{
              current: _vm.pagination.onpage,
              pageSize: _vm.pagination.pageSize,
              total: _vm.pagination.total,
              showSizeChanger: false,
              showLessItems: true,
              showQuickJumper: true,
              onChange: _vm.onChange,
              onShowSizeChange: _vm.onSizeChange,
            }},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{key:item.sys_fld_doi},[_c('div',{staticClass:"res-list res-list-1 show"},[_c('ul',{staticClass:"book-list clearfix"},[_c('li',[_c('div',{staticClass:"pic"},[(_vm.type === 'audio')?_c('a',[_c('img',{attrs:{"src":item.sys_fld_coverpath,"title":item.name},on:{"click":function($event){return _vm.goRouter('AudioDetail', item.sys_fld_doi)}}})]):_vm._e(),(_vm.type === 'radio')?_c('a',[_c('img',{attrs:{"src":item.sys_fld_coverpath,"title":item.name},on:{"click":function($event){return _vm.goRouter('videoDetail',item.sys_fld_doi)}}})]):_vm._e(),_c('div',{staticClass:"icon-play"},[(_vm.type === 'audio')?_c('img',{attrs:{"src":require("@/assets/images/btn-play.png"),"title":item.name},on:{"click":function($event){return _vm.goRouter('AudioDetail', item.sys_fld_doi)}}}):_vm._e(),(_vm.type === 'radio')?_c('img',{attrs:{"src":require("@/assets/images/icon-play.png"),"title":item.name},on:{"click":function($event){return _vm.goRouter('videoDetail',item.sys_fld_doi)}}}):_vm._e()])]),_c('div',{staticClass:"text"},[_c('h4',[(_vm.type === 'radio')?_c('a',{attrs:{"title":item.name},domProps:{"innerHTML":_vm._s(item.name)},on:{"click":function($event){return _vm.goRouter('videoDetail',item.sys_fld_doi)}}}):_vm._e(),(_vm.type === 'audio')?_c('a',{attrs:{"title":item.name},domProps:{"innerHTML":_vm._s(item.name)},on:{"click":function($event){return _vm.goRouter('AudioDetail', item.sys_fld_doi)}}}):_vm._e()]),_c('p',[_vm._v(_vm._s(item.issuedep))])])])])])])}}],null,false,651211324)})],1):_vm._e(),(_vm.type === 'activity')?_c('div',{staticClass:"main-item clearfix"},[_c('a-list',{attrs:{"item-layout":"vertical","data-source":_vm.activityList,"pagination":{
              current: _vm.pagination.onpage,
              pageSize: _vm.pagination.pageSize,
              total: _vm.pagination.total,
              showSizeChanger: false,
              showLessItems: true,
              showQuickJumper: true,
              onChange: _vm.onChange,
              onShowSizeChange: _vm.onSizeChange,
            }},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{key:item.urlid},[_c('div',{staticClass:"activityStyle"},[_c('div',[_c('a',{attrs:{"title":item.title},domProps:{"innerHTML":_vm._s(item.title)},on:{"click":function($event){return _vm.goRouter('newsDetail',item.urlid,0)}}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.pubdate))])])])])}}],null,false,3285043683)})],1):_vm._e(),(_vm.type === 'news')?_c('div',{staticClass:"main-item clearfix"},[_c('a-list',{staticClass:"news-list",attrs:{"item-layout":"vertical","data-source":_vm.newList,"pagination":{
              current: _vm.pagination.onpage,
              pageSize: _vm.pagination.pageSize,
              total: _vm.pagination.total,
              showSizeChanger: false,
              showLessItems: true,
              showQuickJumper: true,
              onChange: _vm.onChange,
              onShowSizeChange: _vm.onSizeChange,
            }},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{key:item.urlid},[_c('h4',[_c('a',{attrs:{"title":item.title},domProps:{"innerHTML":_vm._s(item.title)},on:{"click":function($event){return _vm.goRouter('newsDetail',item.urlid,1)}}})]),_c('div',{staticClass:"news clearfix"},[_c('a',{staticClass:"news-pic fl"},[_c('img',{attrs:{"src":item.sys_fld_coverpath},on:{"click":function($event){return _vm.goRouter('newsDetail',item.urlid,1)}}})]),_c('div',{staticClass:"news-info fr"},[_c('p',[_vm._v(" "+_vm._s(item.digest)+" ")]),_c('div',[_c('span',[_vm._v(_vm._s(item.pubdate))]),_c('a',{on:{"click":function($event){return _vm.goRouter('newsDetail',item.urlid,1)}}},[_vm._v("查看详情")])])])])])}}],null,false,57692626)})],1):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('span',[_c('i'),_vm._v("搜索结果")])])}]

export { render, staticRenderFns }
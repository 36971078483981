<template>
  <div class="form">
    <a-form-model
      layout="horizontal"
      :model="updatePasswordFrom"
      ref="updatePasswordFrom"
      :rules="updatePasswordRules"
    >
      <a-form-model-item prop="user">
        <a-input
          v-model="updatePasswordFrom.user"
          disabled
          placeholder="请输入手机号码"
        >
          <a-icon
            slot="prefix"
            type="user"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="oldPassword">
        <a-input
          v-model="updatePasswordFrom.oldPassword"
          type="password"
          placeholder="请输入旧密码"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="password">
        <a-input
          v-model="updatePasswordFrom.password"
          type="password"
          placeholder="请输入新密码"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="cpassword">
        <a-input
          v-model="updatePasswordFrom.cpassword"
          type="password"
          placeholder="请确认新密码"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-model-item>
      <a-form-model-item>
        <a-button
          type="primary"
          html-type="submit"
          @click="confirm('updatePasswordFrom')"
        >
          确认
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import * as Api from "@/services/userCenter.js";
import { mapGetters } from "vuex";
export default {
  name: "UpdatePassWord",
  components: {},
  data() {
    var vm = this;
    let validatePass5 = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请输入手机号"));
      } else {
        let reg = /^1([3|5|6|7|8|9])\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("手机号格式有错误"));
        }
      }
    };
    let validatePass6 = (rule, value, callback) => {
      let reg = /[^\w]/;
      if (value === "" || !value) {
        callback(new Error("请输入密码"));
      } else {
        if (value.length <= 6) {
          callback(new Error("密码长度必须大于6位"));
        } else if (reg.test(value)) {
          callback(new Error("密码只能是数字或字母！"));
        } else {
          if (value === vm.updatePasswordFrom.oldPassword) {
            callback(new Error("新密码和旧密码不能相同！"));
          } else {
            callback();
          }
        }
      }
    };
    let validatePass7 = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请输入确认密码"));
      } else {
        if (value !== vm.updatePasswordFrom.password) {
          callback(new Error("确认密码与密码不一致！"));
        } else {
          callback();
        }
      }
    };
    let validatePass = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请输入旧密码"));
      } else {
        let obj = {
          username: this.updatePasswordFrom.user,
          password: value,
        };
        //校验旧密码是否正确
        Api.checkuser(obj).then((res) => {
          // log
          if (!res.success) {
            callback(new Error("验证失败,密码不正确！"))
          } else {
            callback();
          }
        });
      }
    };
    return {
      oldPassword: "",
      updatePasswordFrom: {
        //注册条件
        user: "",
        oldPassword: "",
        password: "",
        cpassword: "",
      },
      updatePasswordRules: {
        //注册校验
        user: [{ validator: validatePass5, trigger: "change" }],
        password: [{ validator: validatePass6, trigger: "change" }],
        cpassword: [{ validator: validatePass7, trigger: "change" }],
        oldPassword: [{ validator: validatePass, trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {
    this.updatePasswordFrom.user = this.user
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    //确认
    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            username: this.updatePasswordFrom.user,
            password: this.updatePasswordFrom.password,
          };
          //   alert("已注册!");
          Api.updatepwd(obj).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          console.log("error 注册!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.form {
  width: 400px;
  margin: 0 auto;
  /* text-align: center; */
}
.form >>> .ant-form-item-children {
  width: 100%;
}
.form >>> .ant-form-item {
  margin-bottom: 0;
}
.form >>> .ant-form-item-control-wrapper {
  height: 65px;
}
.form >>> .ant-form-item span i {
  font-size: 23px;
}
.form >>> .ant-form-item-children input {
  height: 40px;
  padding-left: 40px;
}
.form >>> .ant-form-item-children button {
  width: 100%;
  height: 44px;
  line-height: 44px;
  background: #ca0d19;
  border-radius: 6px;
  font-size: 16px;
  color: #fff;
  touch-action: manipulation;
  cursor: pointer;
  margin-top: 10px;
}
</style>

<template>
  <div class="container">
    <div class="main-item position">
      <a @click="goToView('/home/index')">首页</a>
      &gt;
      <span>新闻资讯</span>
    </div>
    <div class="main-item">
      <div>
        <div class="box">
          <div class="title">
            <span> <i></i>新闻资讯</span>
          </div>
          <a-list
            item-layout="vertical"
            :data-source="newDataList"
            :pagination="{
              current: pagination.onpage,
              pageSize: pagination.pageSize,
              total: pagination.total,
              showSizeChanger: false,
              showLessItems: true,
              showQuickJumper: true,
              onChange: onChange,
              onShowSizeChange: onSizeChange,
            }"
            class="news-list"
          >
            <!-- <div slot="header">共搜索到{{ pagination.total }}条记录</div> -->
            <a-list-item slot="renderItem" :key="item.id" slot-scope="item">
              <h4>
                <a
                  @click="goRouter('newsDetail', item.urlid)"
                  :title="item.title"
                  >{{ item.title }}</a
                >
              </h4>
              <div class="news clearfix">
                <a
                  @click="goRouter('newsDetail', item.urlid)"
                  class="news-pic fl"
                >
                  <img :src="item.sys_fld_coverpath" />
                </a>
                <div class="news-info fr">
                  <p>
                    {{ item.digest }}
                  </p>
                  <div>
                    <span>{{ item.pubdate }}</span>
                    <a @click="goRouter('newsDetail', item.urlid)">查看详情</a>
                  </div>
                </div>
              </div>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as homeApi from "@/services/home";
import { replacePath, isMobile } from "@/utils/util";
export default {
  name: "news",
  components: {},
  data() {
    return {
      pagination: {
        page: 1,
        pageSize: 8,
        pagecount: 1, //资源总页数
        total: 0, //资源总数
        onpage: 1, //每次翻页后，列表组件回返回当前页面并更新此变量，重新请求
      },
      newDataList: [],
    };
  },
  created() {
    this.Mobile();
    this.listrequest();
  },
  methods: {
    Mobile() {
      if (isMobile()) {
        this.$router.push({ name: "mobileNews" });
      }
    },

    goToView(path) {
      this.$router.push({ path: path });
    },
    //跳转
    goRouter(name, query) {
      this.$router.push({
        name: name,
        params: {
          doi: query,
          type: 1,
        },
      });
    },
    //获取资讯信息
    listrequest() {
      let obj = {
        page: this.pagination.page,
        size: this.pagination.pageSize,
        typeid: "1",
      };

      homeApi.getNewsList(obj).then((res) => {
        console.log(res);
        if (res.success) {
          this.newDataList = res.data.list;
          this.newDataList.map((item) => {
            item.sys_fld_coverpath = replacePath(item.sys_fld_coverpath);
          });
          this.pagination.total = res.data.count;
        } else {
          this.newDataList = [];
        }
      });
    },
    onSizeChange(current, size) {
      this.pagination.page = 1;
      this.pagination.onpage = 1;
      this.pagination.pageSize = size;
      // this.listrequest(1)
    },
    onChange(pageNumber, pageSize) {
      // 翻页时触发,分页组件绑定页数需求number类型
      this.pagination.onpage = pageNumber;
      this.pagination.page = pageNumber;
      this.pagination.pageSize = pageSize;
      this.listrequest();
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/css/home.less";
.news-info div {
  padding-top: 20px;
}

img {
  width: 100%;
  height: 100%;
}
</style>
<style scoped>
.container >>> .ant-list-pagination li {
  padding: 0 !important;
  border: 1px solid #d9d9d9;
}
</style>

import { request, METHOD } from "@/utils/request";
import { addurl } from "@/utils/util";
import { requestApiUrl } from "@/config/constConfig";

/**
 * 获取旧密码
 * @param page
 * @param pagesize
 */
 export async function checkuser(params) {
    return request(
      addurl("checkuser", requestApiUrl.requestAccount),
      METHOD.POST,params
    );
  }
/**
 * 修改密码
 * @param page
 * @param pagesize
 */
 export async function updatepwd(params) {
    return request(
      addurl("updatepwd", requestApiUrl.requestAccount),
      METHOD.POST,params
    );
  }
/**
 * 获取收藏列表页
 * @param page
 * @param pagesize
 */
 export async function getFavorList(params) {
    return request(
      addurl("getList", requestApiUrl.requestFavoritedata) +
        "/" +
        params.page +
        "/" +
        params.size,
      METHOD.GET,
      {
        name: params.name,
        restype: params.restype,
      }
    );
  }
   /**
 * 删除收藏
 * @param page
 * @param pagesize
 */
 export async function removeList(params) {
    return request(
      addurl("remove", requestApiUrl.requestFavoritedata) + "/" +
      params.doi,
      METHOD.DELETE
    );
  }
  /**
 * 获取浏览记录列表页
 * @param page
 * @param pagesize
 */
 export async function getLogList(params) {
    return request(
      addurl("getList", requestApiUrl.requestLog) +
        "/" +
        params.page +
        "/" +
        params.size,
      METHOD.GET,
      {
        name: params.name,
        restype: params.restype,
      }
    );
  }
   /**
 * 删除浏览信息
 * @param page
 * @param pagesize
 */
 export async function logRemove(params) {
    return request(
      addurl("remove", requestApiUrl.requestLog) +
        "/" +
        params.doi,
      METHOD.DELETE
    );
  }

<template>
  <div id="main">
    <header class="header">
      <div class="newcontainer">
        <div class="row">
          <div class="col-xs-12 header-title">
            <h4>修改密码</h4>
            <a @click="goToView('/mobileHome/mobileUserCenter')" class="back"
              ><i></i
            ></a>
          </div>
        </div>
      </div>
    </header>
    <div class="content-wrap">
      <div class="form">
        <a-form-model
          layout="horizontal"
          :model="updatePasswordFrom"
          ref="updatePasswordFrom"
          :rules="updatePasswordRules"
        >
          <a-form-model-item prop="user">
            <a-input
              v-model="updatePasswordFrom.user"
              disabled
              placeholder="请输入手机号码"
            >
              <a-icon
                slot="prefix"
                type="user"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-form-model-item>
          <a-form-model-item prop="oldPassword">
            <a-input
              v-model="updatePasswordFrom.oldPassword"
              type="password"
              placeholder="请输入旧密码"
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-form-model-item>
          <a-form-model-item prop="password">
            <a-input
              v-model="updatePasswordFrom.password"
              type="password"
              placeholder="请输入新密码"
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-form-model-item>
          <a-form-model-item prop="cpassword">
            <a-input
              v-model="updatePasswordFrom.cpassword"
              type="password"
              placeholder="请确认新密码"
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-form-model-item>
          <!-- <a-form-model-item>
          <a-button
            type="primary"
            html-type="submit"
            @click="confirm('updatePasswordFrom')"
          >
            确认
          </a-button>
        </a-form-model-item> -->
        </a-form-model>
        <div class="button">
          <a-button
            type="primary"
            html-type="submit"
            @click="confirm('updatePasswordFrom')"
          >
            确认
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Api from "@/services/userCenter.js";
import { mapGetters, mapMutations } from "vuex";
import { removeAuthorization } from "@/utils/request";
import { isMobile } from "@/utils/util";
export default {
  name: "UpdatePassword",
  components: {},
  data() {
    var vm = this;
    let validatePass5 = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请输入手机号"));
      } else {
        let reg = /^1([3|5|6|7|8|9])\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("手机号格式有错误"));
        }
      }
    };
    let validatePass6 = (rule, value, callback) => {
      //let reg = /[^\w]/
      console.log(1);
      var reg =
        /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,12}$/;
      if (value === "" || !value) {
        callback(new Error("请输入密码"));
      } else {
        if (value.length < 8 || value.length > 12) {
          callback(new Error("密码长度为8到12位"));
        } else {
          if (value === vm.updatePasswordFrom.oldPassword) {
            callback(new Error("新密码和旧密码不能相同！"));
          } else if (reg.test(value)) {
            callback();
          } else {
            callback(new Error("密码必须是大写字母，小写字母，数字组成！"));
          }
        }
      }
    };
    let validatePass7 = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请输入确认密码"));
      } else {
        if (value !== vm.updatePasswordFrom.password) {
          callback(new Error("确认密码与密码不一致！"));
        } else {
          callback();
        }
      }
    };
    let validatePass = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请输入旧密码"));
      } else {
        let obj = {
          username: this.updatePasswordFrom.user,
          password: value,
        };
        //校验旧密码是否正确
        Api.checkuser(obj).then((res) => {
          // log
          if (!res.success) {
            callback(new Error("验证失败,密码不正确！"));
          } else {
            callback();
          }
        });
      }
    };
    return {
      oldPassword: "",
      updatePasswordFrom: {
        //注册条件
        user: "",
        oldPassword: "",
        password: "",
        cpassword: "",
      },
      updatePasswordRules: {
        //注册校验
        user: [{ validator: validatePass5, trigger: "change" }],
        password: [{ validator: validatePass6, trigger: "change" }],
        cpassword: [{ validator: validatePass7, trigger: "change" }],
        oldPassword: [{ validator: validatePass, trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {
    this.updatePasswordFrom.user = this.user;
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    ...mapMutations("account", ["removeUser", "removeRoles", "removeExpireAt"]),
    goToView(path) {
      this.$router.push({ path: path });
    },
    Mobile() {
      if (!isMobile()) {
        this.$router.push({ name: "userCenter" });
      }
    },
    //确认
    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            username: this.updatePasswordFrom.user,
            password: this.updatePasswordFrom.password,
          };
          //   alert("已注册!");
          Api.updatepwd(obj).then((res) => {
            if (res.success) {
              this.$message.success("修改密码成功，请重新登录");
              this.removeUser();
              this.removeRoles();
              this.removeExpireAt();
              removeAuthorization();
              this.$router.push({
                name: "mobileLogin",
              });
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          console.log("error 修改密码!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
#main {
  .newcontainer {
    width: 100%;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    margin-right: auto;
    margin-left: auto;
  }
  .header {
    width: 100%;
    padding: 12px 0 2px 0;

    .newcontainer {
      width: 100%;
      padding-right: 0.3rem;
      padding-left: 0.3rem;
      margin-right: auto;
      margin-left: auto;
      .row {
        margin-right: -0.3rem;
        margin-left: -0.3rem;
        .header-title {
          position: relative;
          h4 {
            text-align: center;
            font-size: 0.28rem;
            color: #232323;
            font-weight: 600;
            padding: 0 0.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .back {
            position: absolute;
            left: 0.3rem;
            top: 0rem;
            font-size: 0.28rem;
            color: #fff;
            i {
              width: 0.2rem;
              height: 0.28rem;
              margin-top: -0.04rem;
              margin-right: 0.08rem;
              font-size: 0.28rem;
              color: #232323;
              background: url(~@/assets/images/icon-back.png) no-repeat;
              background-size: 100% auto;
              vertical-align: middle;
            }
          }
        }
        .col-xs-12 {
          width: 100%;
        }
      }
    }
  }
  .content-wrap {
    background-color: #fff;
    height: 100vh;
    padding-top: 10%;
    .form {
      width: 90%;
      margin-left: 5%;
      padding: 0.4rem;
      border: 2px solid #ffcaca;
      background: #fff;
      border-radius: 5px;
    }
  }
}
</style>
<style scoped>
.form >>> .ant-form-item-children {
  width: 100%;
}
.form >>> .ant-form-item {
  margin-bottom: 0;
}
.form >>> .ant-form-item-control-wrapper {
  height: 1.3rem;
}
.form >>> .ant-form-item span i {
  font-size: 0.46rem;
}
.form >>> .ant-form-item-children input {
  height: 0.8rem;
  padding-left: 0.8rem;
  border-radius: 5px;
}
.form .button >>> .ant-btn-primary {
  position: absolute;
  margin-top: 22%;
  margin-left: -7%;
  font-size: 0.36rem;
  background: url(~@/assets/images/btn-bg.png) no-repeat top center;
  background-size: 100% 100%;
  width: 6.8rem;
  height: 0.9rem;
  border-radius: 5px;
  background-color: #ca0d19;
  border-color: #d4000e;
}
</style>

<template>
  <div>
    <div id="main">
      <div class="container">
        <div class="main-item position">
          <a @click="goToView('/home/index')">首页</a>
          &gt;
          <a
            @click="goToView(type == 0 ? '/list/activityList' : '/list/news')"
            >{{ type == 1 ? "新闻资讯" : "活动预告" }}</a
          >
          &gt;
          <span>{{ type == 1 ? "资讯详情" : "活动详情" }}</span>
        </div>
        <div class="main-item">
          <div class="details">
            <div class="box ql-snow">
              <h4>{{ detail.title }}</h4>
              <span>{{ detail.pubdate }}</span>
              <div class="con editor quill-editor ql-editor" v-html="detail.content"></div>
              <div class="link">
                <template v-if="previd != null && previd != ''">
                  <p>
                    <a @click="PrevAndNext('newsDetail', previd)"
                      >上一篇：{{ prevtitle }}</a
                    >
                  </p>
                </template>
                <template v-if="nextid != null && nextid != ''">
                  <p>
                    <a @click="PrevAndNext('newsDetail', nextid)"
                      >下一篇：{{ nexttitle }}</a
                    >
                  </p>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

import * as Api from "@/services/activity";
import { silceTime, isMobile } from "@/utils/util";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "News",
  components: {},
  data() {
    return {
      doi: this.$route.params.doi,
      type: this.$route.params.type,
      detail: {},
      nextid: "",
      previd: "",
      nexttitle: "",
      prevtitle: "",
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    this.Mobile();
    this.initDetail();
    this.initPrevAndNext();
  },
  watch: {
    $route(to) {
      this.doi = to.params.doi;
      this.initDetail();
      this.initPrevAndNext();
    },
  },
  methods: {
    Mobile() {
      if (isMobile()) {
        this.$router.push({
          name: "MobileNewsDetail",
          params: {
            doi: this.$route.params.doi,
            type: this.$route.params.type,
          },
        });
      }
    },
    PrevAndNext(name, doi) {
      this.$router.push({ name: name, params: { doi: doi, type: this.type } });
      // this.doi = doi;
      // this.initDetail();
      // this.initPrevAndNext();
    },
    goToView(path) {
      this.$router.push({ path: path });
    },
    ...mapMutations("account", ["saveNowRouter"]),
    initDetail() {
      Api.getInfo(this.doi).then((res) => {
        const { success } = res;
        //console.log(res)
        if (success) {
          const {
            data: { data },
          } = res;
          this.detail = data;
          if (this.detail.isloginlook === "1") {
            var vm = this;
            if (!this.user || this.user === "") {
              this.$confirm({
                title: "确认提示",
                content: `登录后即可阅读，是否确认登录？`,
                okType: "warning",
                onOk() {
                  vm.saveNowRouter(vm.$route);
                  vm.$router.push({ name: "Login" });
                },
                onCancel() {
                 vm.$router.push({ name: "newsIndex" });
                },
              });
              return;
            }
            if (
              localStorage.getItem("expireAt") &&
              new Date().getTime() >=
                new Date(localStorage.getItem("expireAt")).getTime()
            ) {
              this.$confirm({
                title: "确认提示",
                content: `登录已过期，该操作需重新登录，是否重新登录？`,
                okType: "warning",
                onOk() {
                  vm.saveNowRouter(vm.$route);
                  vm.$router.push({ name: "Login" });
                },
                onCancel() {
                  vm.$router.push({ name: "newsIndex" });
                },
              });
              return;
            }
          }

          this.detail.pubdate = silceTime(this.detail.pubdate);
        } else {
          this.detail = {};
        }
      });
    },
    initPrevAndNext() {
      Api.getPrevAndNextById(this.doi).then((res) => {
        let data = res;
        if (data.success) {
          this.nextid = data.data.nextid;
          this.previd = data.data.previd;
          this.nexttitle = data.data.nexttitle;
          this.prevtitle = data.data.prevtitle;
        } else {
          this.detail = {};
        }
      });
    },
  },
};
</script>
<style lang="less">
@import "~@/assets/css/details-news.less";
</style>
<style scoped>
/* #main {
  margin-top: -57px;
} */
.box {
  padding: 28px 60px;
}
</style>